import {
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
import "./Viewbooking.css";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { Geolocation } from '@ionic-native/geolocation';
import { addOutline, removeOutline } from "ionicons/icons";
import React from "react";

import { Network } from "@capacitor/network";
import WatiChat from "../../components/WatiChat";

const Viewbooking: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");

  const history = useHistory();
  const validInput = /^[A-Za-z0-9 \n]*$/;
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);

  // const [latitude, setLatitude] = useState<number | null>(null);
  // const [longitude, setLongitude] = useState<number | null>(null);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [activeAccordionIndex, setActiveAccordionIndex] = useState([
    true,
    true,
    true,
    true,
  ]);
  // type LocationState = {
  //     data: null;
  // }
  // const location = useLocation<LocationState>();
  // const Id = location.state?.data;
  const [bookingId, setBookingId] = useState("");
  const [bookingStatus, setBookingStatus] = useState(null);
  const [booking, setbooking] = useState<any>();
  const [scrollImages, setScrollImages] = useState<Array<any>>([]);
  const [oneImage, setOneImage] = useState<Array<any>>([]);
  const [extraPerson, setExtraPerson] = useState<Array<any>>([]);

  const [selectedValue, setSelectedValue] = useState("");
  const [rejecttextarea, setRejectTextArea] = useState("");
  const latestInputAcceptValue = useRef("");
  const latestInputRejectValue = useRef("");
  const latestInputRejectDescriptionValue = useRef("");
  const [textareaErrorMessage, setTextareaErrorMessage] = useState("");
  const [cnfmNameErrorMessage, setCnfmNameErrorMessage] = useState("");
  const [rejectNameErrorMessage, setRejectNameErrorMessage] = useState("");
  const [isRejectChecked, setIsRejectChecked] = useState(false);
  const [rejectname, setRejectName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expired, setExpired] = useState(Boolean);
  const [showToast, setShowToast] = useState(false);

  const [confirmname, setFormState] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showConfirmedModal, setShowConfirmedModal] = useState(false);
  const [showRejectCnfmModal, setShowRejectCnfmModal] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState("");
  const [supendedTitle, setSupendedTitle] = useState("");

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      setNetworkStatus(status.connected);
    });
  }, []);

  useEffect(() => {
   
    setUnauthorizedHandler(() => {
      setSupendedMsg("Your account is inactive, please contact support.");
      setSupendedTitle("Inactive");
      setShowSuspendedModal(true);
    });

    return () => {
      setUnauthorizedHandler(() => {});
    };
  }, []);

  useIonViewWillEnter(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    } else {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig
      .get(
        `/bookings/${localStorage.getItem("bookingId")}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setbooking(response.data.data);
        if (response.data.data.extra_person.length !== 0) {
          setExtraPerson(response.data.data.extra_person);
        }
        setExpired(response.data.data.expired);
        localStorage.setItem("confirmed", "");
        if (response.data.data.listing_style.images.length == 1) {
          setOneImage(response.data.data.listing_style.images);
          setScrollImages([]);
        } else {
          setOneImage([]);
          setScrollImages(response.data.data.listing_style.images);
        }
        localStorage.setItem("bookingIdPopup", response.data.data.booking_id);
        setBookingId(response.data.data.booking_id);
        setBookingStatus(response.data.data.status);

        if (
          response.data.data.status === 3 ||
          response.data.data.status === 7
        ) {
          localStorage.setItem("viewallbookings", "viewall");
        } else if (response.data.data.status === 4) {
          localStorage.setItem("upcomingBookings", "upcoming");
        } else if (response.data.data.status === 1) {
          localStorage.setItem("expired", "completed");
        }

        setLoading(false);
        setBackdropVisible(false);

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

    }
    // getLocation();
  }, []);
  const goBack = () => {
    history.goBack();
  };
  const handlesetRejectTextAreaInput = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setRejectTextArea(inputValue);
      latestInputRejectDescriptionValue.current = inputValue;
      setTextareaErrorMessage(""); // clear the error message
    } else if (inputValue.startsWith(" ")) {
      setTextareaErrorMessage("Input cannot start with a space.");
    } else if (!validInput.test(inputValue)) {
      setTextareaErrorMessage(
        "Invalid input. Only alphanumeric characters, spaces, and newlines are allowed."
      );
    } else {
      setRejectTextArea(inputValue);
      latestInputRejectDescriptionValue.current = inputValue;
      setTextareaErrorMessage(""); // clear the error message
    }
  };
  const handleCnfmInput = (event: any) => {
    const inputValue = event.target.value;

    if (inputValue === "") {
      setFormState(inputValue);
      latestInputAcceptValue.current = inputValue;
      setCnfmNameErrorMessage(""); // clear the error message
    } else if (inputValue.startsWith(" ")) {
      setCnfmNameErrorMessage("Input cannot start with a space.");
    } else if (!validInput.test(inputValue)) {
      setCnfmNameErrorMessage(
        "Invalid input. Only alphanumeric characters, spaces, and newlines are allowed."
      );
    } else {
      setFormState(inputValue);
      latestInputAcceptValue.current = inputValue;
      setCnfmNameErrorMessage(""); // clear the error message
    }
  };
  const handleRejectInput = (event: any) => {
    const inputValue = event.target.value;

    if (inputValue === "") {
      setRejectName(inputValue);
      latestInputRejectValue.current = inputValue;
      setRejectNameErrorMessage(""); // clear the error message
    } else if (inputValue.startsWith(" ")) {
      setRejectNameErrorMessage("Input cannot start with a space.");
    } else if (!validInput.test(inputValue)) {
      setRejectNameErrorMessage(
        "Invalid input. Only alphanumeric characters, spaces, and newlines are allowed."
      );
    } else {
      setRejectName(inputValue);
      latestInputRejectValue.current = inputValue;
      setRejectNameErrorMessage(""); // clear the error message
    }
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const closeShowModal = () => {
    setFormState("");
    setIsChecked(false);
    setShowModal(false);
    setTextareaErrorMessage("");
    setCnfmNameErrorMessage("");
    setRejectNameErrorMessage("");
    latestInputAcceptValue.current = "";
    latestInputRejectDescriptionValue.current = "";
    latestInputRejectValue.current = "";
  };

  const confirm = () => {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig
      .patch(
        `/bookings/${localStorage.getItem("bookingId")}`,
        {
          status: 4,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status == true) {
          setShowModal(false);
          localStorage.setItem("confirmed", "Confirmed");
          localStorage.setItem("bookingId", res.data.data.bookingid);
          setBookingStatus(res.data.data.status);
          setbooking(res.data.data);
          setIsChecked(false);
          setLoading(false);
          setShowConfirmedModal(true);
          setBackdropVisible(false);
        } else {
          setErrorMessage(res.data.data.response);
          setShowToast(true);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
  };
  const reject = () => {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig
      .patch(
        `/bookings/${localStorage.getItem("bookingId")}`,
        {
          status: 5,
          reject_reason: selectedValue,
          reject_notes: latestInputRejectDescriptionValue.current,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status == true) {
          setIsRejectChecked(false);
          setbooking(res.data.details);
          setShowRejectCnfmModal(false);
          setShowRejectedModal(true);
          setRejectName("");
          setSelectedValue("");
          setRejectTextArea("");
          setTextareaErrorMessage("");
          setCnfmNameErrorMessage("");
          setRejectNameErrorMessage("");
          latestInputRejectDescriptionValue.current = "";
          latestInputRejectValue.current = "";
          setbooking([]);
          // unConfirmFunction();
          // setShowRejectedAlert(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setErrorMessage(res.data.data.response);
          setShowToast(true);
          setLoading(false);
          setBackdropVisible(false);
        }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
  };
  // const getLocation = async () => {
  //     try {
  //         const position = await Geolocation.getCurrentPosition();
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //     } catch (error) {
  //         console.error('Error getting current location', error);
  //     }
  // };

  const toggleAccordion = (index: any) => {
    const updatedState = activeAccordionIndex.map((_, i) => i === index);

    setActiveAccordionIndex(updatedState);
  };

  const isAccordionActive = (index: any) => activeAccordionIndex[index];

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleRejectCheckboxChange = (event: any) => {
    setIsRejectChecked(event.target.checked);
  };
  const handleOkayClick = () => {
    if (
      selectedValue == "" ||
      latestInputRejectDescriptionValue.current == ""
    ) {
      setErrorMessage("Please enter both the inputs");
      setShowToast(true);
    } else {
      setShowRejectCnfmModal(true);
      setShowRejectModal(false);
    }
  };
  const closeRejectModal = () => {
    setRejectName("");
    setSelectedValue("");
    setRejectTextArea("");
    setTextareaErrorMessage("");
    setCnfmNameErrorMessage("");
    setRejectNameErrorMessage("");
    latestInputAcceptValue.current = "";
    latestInputRejectDescriptionValue.current = "";
    latestInputRejectValue.current = "";
    setIsRejectChecked(false);
    setShowRejectModal(false);
  };
  const closeRejectCnfmModal = () => {
    setRejectName("");
    setSelectedValue("");
    setRejectTextArea("");
    setTextareaErrorMessage("");
    setCnfmNameErrorMessage("");
    setRejectNameErrorMessage("");
    latestInputAcceptValue.current = "";
    latestInputRejectDescriptionValue.current = "";
    latestInputRejectValue.current = "";
    setIsRejectChecked(false);
    setShowRejectCnfmModal(false);
  };
  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-left dark-ion-title">
                Booking Information
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="no-network-div">
              <IonImg
                className="no-network-img"
                src="assets/imgs/images/darkNoNetwork.svg"
              ></IonImg>
              <h6 style={{ color: "#CCC" }} className="fontName">
                Check your internet connection and try again
              </h6>
            </div>
          </IonContent>
        </>
      )}
      {networkStatus == true && darkModeValue == "darkMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-left dark-ion-title">
                Booking Information
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="the-title booking-title-top ion-padding-horizontal">
              <IonLabel className="dark-bookingid-label">
                Booking ID: {bookingId}
                {expired == false && bookingStatus == "3" && (
                  <span className="modified-chip">Unconfirmed</span>
                )}
                {localStorage.getItem("confirmed") == "Confirmed" && (
                  <span className="confirmed-chip">Confirmed</span>
                )}
                {bookingStatus == "2" && (
                  <span className="confirmed-chip">Confirmed</span>
                )}
                {bookingStatus === 7 && (
                  <span className="modified-chip">Modified</span>
                )}
                {expired == true && (
                  <span className="modified-chip">Completed</span>
                )}
              </IonLabel>
            </div>
            <IonModal
              id="dark-example-modal"
              isOpen={showModal}
              backdropDismiss={false}
            >
              <IonHeader className="ion-no-border">
                <IonToolbar className="fontName bgToolbar">
                  <IonButtons slot="start"></IonButtons>
                  <IonTitle className="fontName dark-model-title ionTitle">
                    Booking ID: {localStorage.getItem("bookingId")}
                    {bookingStatus == "3" && (
                      <span className="popup-modified-chip">Unconfirmed</span>
                    )}
                    {bookingStatus == "2" && (
                      <span className="popup-confirmed-chip">Confirmed</span>
                    )}
                    {bookingStatus == "4" && (
                      <span className="popup-modified-chip">Modified</span>
                    )}
                  </IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="model-sub-div">
                        <IonLabel className="fontName dark-model-sub-title">
                          If you’re ok with the customer’s requirements, please
                          confirm the booking
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          type="text"
                          id="textAreaExample"
                          value={confirmname}
                          onFocus={(e) => e.preventDefault()}
                          onChange={handleCnfmInput}
                          className="dark-form-control-model"
                        />
                        <label
                          className="dark-label-outline-model"
                          htmlFor="textAreaExample"
                        >
                          Your Name
                        </label>
                      </div>
                      <div className="fontName emailErrorMsg">
                        {cnfmNameErrorMessage}
                      </div>
                      {/* <IonLabel style={{ marginLeft: "0%" }} className='fontName inputTitle'>Enter Your Name</IonLabel>
                                    <IonToolbar className='InputIonToolbar'>
                                        <input
                                            className='cnfmModelInput fontName'
                                            placeholder='Name'
                                            value={confirmname}
                                            onFocus={(e) => e.preventDefault()}
                                            onChange={handleCnfmInput}
                                        ></input>
                                    </IonToolbar>
                                    <div className='fontName emailErrorMsg'>{cnfmNameErrorMessage}</div> */}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="fontName model-sub-div">
                        <IonLabel className="dark-model-note">
                          Note : Enter the name of the person confirming the
                          booking
                        </IonLabel>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ margin: "-4px 0px 7px 0px" }}>
                    <IonCol size="12" col-12>
                      <div className="checkbx-div">
                        <IonCheckbox
                          checked={isChecked}
                          className="fontName"
                          mode="ios"
                          onIonChange={handleCheckboxChange}
                        ></IonCheckbox>
                        <IonLabel className="checkbx-label">
                          I Agree with the{" "}
                          <span className="fontName">
                            <a
                              className="checkbx-span"
                              href="https://anythingforhire.com/terms"
                            >
                              Terms & Conditions
                            </a>
                          </span>
                        </IonLabel>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={closeShowModal}
                        className="fontName no-btn"
                        type="submit"
                      >
                        No
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={confirm}
                        className="fontName yes-accept-btn"
                        id="open-customa-dialog"
                        expand="block"
                      >
                        Accept
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              isOpen={showConfirmedModal}
              id="dark-example-modal-confirmed"
              backdropDismiss={false}
            >
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/tick.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-b-cnfmed-title">
                        Booking Confirmed!
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-thanks-cnfmed-title">
                        Thank you for confirming the Booking ID:{" "}
                        {localStorage.getItem("bookingId")}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        className="fontName booking-details-btn"
                        onClick={() => setShowConfirmedModal(false)}
                        expand="block"
                      >
                        Back to Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              id="dark-example-modal-reject"
              isOpen={showRejectModal}
              backdropDismiss={false}
            >
              <IonHeader className="ion-no-border">
                <IonToolbar className="fontName bgToolbar">
                  <IonButtons slot="start"></IonButtons>
                  <IonTitle className="fontName dark-model-title ionTitle">
                    Booking ID: {localStorage.getItem("bookingId")}
                    {bookingStatus == "3" && (
                      <span className="popup-modified-chip">Unconfirmed</span>
                    )}
                    {localStorage.getItem("confirmed") == "Confirmed" && (
                      <span className="popup-confirmed-chip">Confirmed</span>
                    )}
                    {bookingStatus == "2" && (
                      <span className="popup-confirmed-chip">Confirmed</span>
                    )}
                    {bookingStatus == "4" && (
                      <span className="popup-modified-chip">Modified</span>
                    )}
                  </IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="model-sub-div">
                        <IonLabel className="fontName dark-model-sub-title">
                          Are you sure do you want reject this Booking?
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonSelect
                          interface="popover"
                          className="fontName dark-form-control-model"
                          placeholder="--select reason--"
                          value={selectedValue}
                          onIonChange={handleSelectChange}
                        >
                          <IonSelectOption value="">
                            Choose Option
                          </IonSelectOption>
                          <IonSelectOption value="Not available">
                            Not available
                          </IonSelectOption>
                          <IonSelectOption value="Taken own booking">
                            Taken own booking
                          </IonSelectOption>
                          <IonSelectOption value="Illness">
                            Illness
                          </IonSelectOption>
                        </IonSelect>
                        <label
                          className="dark-label-outline-model"
                          htmlFor="textAreaExample"
                        >
                          Reason for Rejection
                        </label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div
                        style={{ marginTop: "4%" }}
                        className="model-container"
                      >
                        <textarea
                          placeholder="Type something here"
                          rows={4}
                          className="fontName dark-form-control-model text-area-css"
                          value={rejecttextarea}
                          onChange={handlesetRejectTextAreaInput}
                        ></textarea>
                        <label
                          className="dark-label-outline-model"
                          htmlFor="textAreaExample"
                        >
                          Notes
                        </label>
                      </div>
                      <div className="fontName emailErrorMsg">
                        {textareaErrorMessage}
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="checkbx-div">
                        <IonCheckbox
                          className="fontName"
                          mode="ios"
                          checked={isRejectChecked}
                          onIonChange={handleRejectCheckboxChange}
                        ></IonCheckbox>
                        <IonLabel className="checkbx-label">
                          I am authorised for this booking to be rejected
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className="dark-form-control-model fontName"
                          placeholder="Name"
                          value={rejectname}
                          onChange={handleRejectInput}
                        ></input>
                        <label
                          className="dark-label-outline-model"
                          htmlFor="textAreaExample"
                        >
                          Enter Your Name
                        </label>
                      </div>
                      <div className="fontName emailErrorMsg">
                        {rejectNameErrorMessage}
                      </div>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={closeRejectModal}
                        className="fontName dark-reject-btn"
                        type="submit"
                      >
                        Close
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={handleOkayClick}
                        className="fontName booking-details-btn"
                        id="open-customa-dialog"
                        expand="block"
                      >
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              isOpen={showRejectCnfmModal}
              id="dark-example-modal-confirmed"
              backdropDismiss={false}
            >
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/warning.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-b-cnfmed-title">
                        Do you want to Reject?
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-thanks-cnfmed-title">
                        Are you sure you want to reject the Booking ID:{" "}
                        {localStorage.getItem("bookingIdPopup")}?
                      </IonLabel>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={closeRejectCnfmModal}
                        className="fontName dark-reject-btn"
                        type="submit"
                      >
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={reject}
                        className="fontName booking-details-btn"
                        id="open-customa-dialog"
                        expand="block"
                      >
                        Okay
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              isOpen={showRejectedModal}
              id="dark-example-modal-confirmed"
              backdropDismiss={false}
            >
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/cancel.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-b-cnfmed-title">
                        Booking Rejected!
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-thanks-cnfmed-title">
                        Booking ID: {localStorage.getItem("bookingIdPopup")} has
                        been rejected as per your request.
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/tabs/bookings"}
                        onClick={() => setShowRejectedModal(false)}
                      >
                        <IonButton
                          style={{ width: "100%", margin: "auto" }}
                          className="fontName booking-details-btn"
                          expand="block"
                        >
                          Back to Booking
                        </IonButton>
                      </Link>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonModal
              isOpen={showSuspendedModal}
              id="dark-example-modal-inactive"
              backdropDismiss={false}
            >
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/warning.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-b-cnfmed-title">
                        {supendedTitle}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-thanks-cnfmed-title">
                        {supendedMsg}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className="fontName booking-details-btn"
                        onClick={() => {
                          closeRejectCnfmModal();
                          closeShowModal();
                          setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");
                        }}
                        expand="block"
                      >
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      )}
      {networkStatus == false && darkModeValue == "lightMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-left">Booking Information</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="wallet">
            <div className="no-network-div">
              <IonImg
                className="no-network-img"
                src="assets/imgs/images/noNetwork.svg"
              ></IonImg>
              <h6 className="fontName">
                Check your internet connection and try again
              </h6>
            </div>
          </IonContent>
        </>
      )}
      {networkStatus == true && darkModeValue == "lightMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-left">Booking Information</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="wallet">
            <div className="the-title ion-padding-horizontal">
              <div className="bookingid-label label-div">
                <p className="booking-id-chip">{bookingId}</p>
                {bookingStatus == 3 && (
                  <p className="unconfirmed-chip">Unconfirmed</p>
                )}
                {bookingStatus == "4" && (
                  <p className="confirmed-chip">Confirmed</p>
                )}
                {bookingStatus == "7" && (
                  <p className="modified-chip">Modified</p>
                )}
                {bookingStatus == "1" && (
                  <p className="expired-chip">Expired</p>
                )}
              </div>
            </div>

            <div className="center-div">
              <div className="slider-holder animate__animated animate__fadeInRight">
                {oneImage.length == 0 && scrollImages.length == 0 && (
                  <IonImg
                    src="assets/imgs/images/no-image.jpg"
                    alt="Product"
                    className="no-image-vd"
                  ></IonImg>
                )}
                {oneImage.length == 1 &&
                  oneImage.map((img, i) => (
                    <div key={i}>
                      <IonImg
                        src={img.url}
                        alt="Product"
                        className="no-image-vd"
                      />
                    </div>
                  ))}
                <Swiper
                  modules={[Autoplay, Pagination]}
                  autoplay={true}
                  pagination={true}
                  spaceBetween={10} // Add some space between the slides
                  breakpoints={{
                    // For mobile devices
                    320: {
                      // Adjust this breakpoint to the minimum width you want to target
                      slidesPerView: 2,
                      spaceBetween: 10, // You can adjust this value as needed
                    },
                    // For tablets
                    768: {
                      // Adjust this breakpoint to the width you want to target for tablets
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    // For desktops
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                >
                  {scrollImages.length > 1 &&
                    scrollImages !== null &&
                    scrollImages.map((img, i) => (
                      <div key={i}>
                        <SwiperSlide>
                          <div className="slide-content ion-text-center padding-img ion-padding-horizontal">
                            <div className="card" id="primary">
                              <IonImg
                                src={img.url}
                                alt="Product"
                                className="product-detail-img"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      </div>
                    ))}
                </Swiper>
              </div>
              {booking && (
                <>
                  {booking.status == 3 && (
                    <>
                      <IonGrid className="btn-grid">
                        <IonRow className="btn-row">
                          <IonCol size="6" col-6>
                            <IonButton
                              expand="block"
                              onClick={() => setShowRejectModal(true)}
                              className="reject-btn"
                            >
                              Reject
                            </IonButton>
                          </IonCol>
                          <IonCol size="6" col-6>
                            <IonButton
                              expand="block"
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="accept-btn"
                            >
                              Accept
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(0)}>
                                                <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Service Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(0) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Services
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_subcategories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event/Occasion
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_categories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">Style</IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_style?.title || "N/A"}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Service Amount
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                            <IonLabel className="value-color">
                              £
                              {(
                                (parseFloat(booking.single) || 0) - 
                                ((parseFloat(booking.baf) || 0) - (parseFloat(booking.extra_baf) || 0))
                              ).toFixed(2)}
                            </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>

                      {extraPerson.length !== 0 && (
                        <>
                          {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(2)}>
                                                        <IonIcon icon={isAccordionActive(2) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                          <IonLabel className="details-title" slot="start">
                            Extra Person
                          </IonLabel>
                          {/* </IonItem> */}
                          {extraPerson &&
                            extraPerson.map((person: any, i: any) => (
                              <div
                                className={`accordion-content ${
                                  isAccordionActive(2) ? "show" : ""
                                }`}
                              >
                                <IonGrid key={i} className="status3-card">
                                  <IonRow>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Name
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.name}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Type of service
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.style_title}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Service Amount
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        £{person.serviceprice}
                                      </IonLabel>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </div>
                            ))}
                        </>
                      )}

                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(1)}>
                                                <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Booking Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(1) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.address}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Location Type
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.location}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event Date / Time
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.book_date}{" "}
                                <span style={{ color: "#12877733" }}>|</span>{" "}
                                {booking.time}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>

                      {/* <div className="the-title p-details-title booking-title-top ion-padding-horizontal"> */}
                      <IonLabel className="details-title">
                        Payment Details
                      </IonLabel>
                      {/* </div> */}
                      <IonCard className="payment-details-card">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="8">
                              <IonLabel className="font-color">
                                Your Price
                                <span className="in-travel-cost">
                                  (Including Travel Cost)
                                </span>
                              </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                              <IonLabel className="p-details-label value-color">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="font-color">
                                Glamdeva Fee
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="p-details-label value-color">
                                £{parseFloat(booking.agency_fee).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow className="p-details-r">
                            <IonCol size="6" col-6>
                              <IonLabel className="balance-pay">
                                Balance to collect
                              </IonLabel>
                            </IonCol>
                            <IonCol
                              className="p-details-last-col"
                              size="6"
                              col-6
                            >
                              <IonLabel className="p-details-label balance-pay">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost) -
                                  parseFloat(booking.agency_fee)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                      {/* <IonGrid>
                                                <IonRow>
                                                    <IonCol size="12" col-12>
                                                        <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                            Go Back
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid> */}
                    </>
                  )}
                </>
              )}
              {booking && (
                <>
                  {booking.status == 7 && (
                    <>
                      <IonGrid className="btn-grid">
                        <IonRow className="btn-row">
                          <IonCol size="6" col-6>
                            <IonButton
                              expand="block"
                              onClick={() => setShowRejectModal(true)}
                              className="reject-btn"
                            >
                              Reject
                            </IonButton>
                          </IonCol>
                          <IonCol size="6" col-6>
                            <IonButton
                              expand="block"
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="accept-btn"
                            >
                              Accept
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(0)}>
                                                <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Service Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(0) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Services
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_subcategories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event/Occasion
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_categories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">Style</IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                              {booking.listing_style?.title || "N/A"}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Service Amount
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                            <IonLabel className="value-color">
                              £
                              {(
                                (parseFloat(booking.single) || 0) - 
                                ((parseFloat(booking.baf) || 0) - (parseFloat(booking.extra_baf) || 0))
                              ).toFixed(2)}
                            </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {extraPerson.length !== 0 && (
                        <>
                          {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(2)}>
                                                        <IonIcon icon={isAccordionActive(2) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                          <IonLabel className="details-title" slot="start">
                            Extra Person
                          </IonLabel>
                          {/* </IonItem> */}
                          {extraPerson &&
                            extraPerson.map((person: any, i: any) => (
                              <div
                                className={`accordion-content ${
                                  isAccordionActive(2) ? "show" : ""
                                }`}
                              >
                                <IonGrid key={i} className="status3-card">
                                  <IonRow>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Name
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.name}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Type of service
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.style_title}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Service Amount
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        £{person.serviceprice}
                                      </IonLabel>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </div>
                            ))}
                        </>
                      )}
                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(1)}>
                                                <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Booking Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(1) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.address}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Location Type
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.location}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event Date / Time
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.book_date}{" "}
                                <span style={{ color: "#12877733" }}>|</span>{" "}
                                {booking.time}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(3)}>
                                                <IonIcon slot="end" icon={isAccordionActive(3) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Customer Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(3) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Full Name
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.customername}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Email Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.email}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Contact Number
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.mobile}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {/* <div className="the-title p-details-title booking-title-top ion-padding-horizontal"> */}
                      <IonLabel className="details-title">
                        Payment Details
                      </IonLabel>
                      {/* </div> */}
                      <IonCard className="payment-details-card">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="8">
                              <IonLabel className="font-color">
                                Your Price
                                <span className="in-travel-cost">
                                  (Including Travel Cost)
                                </span>
                              </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                              <IonLabel className="p-details-label value-color">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="font-color">
                                Glamdeva Fee
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="p-details-label value-color">
                                £{parseFloat(booking.agency_fee).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow className="p-details-r">
                            <IonCol size="6" col-6>
                              <IonLabel className="balance-pay">
                                Balance to collect
                              </IonLabel>
                            </IonCol>
                            <IonCol
                              className="p-details-last-col"
                              size="6"
                              col-6
                            >
                              <IonLabel className="p-details-label balance-pay">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost) -
                                  parseFloat(booking.agency_fee)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                      {/* <IonGrid>
                                                <IonRow>
                                                    <IonCol size="12" col-12>
                                                        <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                            Go Back
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid> */}
                    </>
                  )}
                </>
              )}
              {booking && (
                <>
                  {booking.status == 4 && (
                    <>
                      <IonGrid className="btn-grid">
                        <IonRow className="btn-row">
                          <IonCol size="6" col-6>
                            <IonButton
                              expand="block"
                              className="secondary-button location-btn"
                              onClick={() =>
                                window.open(
                                  `https://www.google.com/maps/dir/${booking.provider_details.address}/${booking.quote_details.address}`
                                )
                              }
                            >
                              <IonIcon
                                className="fav btn-icon"
                                src="assets/imgs/icons/location-btn.svg"
                              />
                              <span className="order-span">view map</span>
                            </IonButton>
                          </IonCol>
                          <IonCol size="6" col-6>
                            <a
                              style={{ textDecoration: "none" }}
                              href={"tel:" + booking.quote_details.mobile}
                            >
                              <IonButton
                                expand="block"
                                className="primary-button call-btn"
                              >
                                <IonIcon
                                  className="fav btn-icon"
                                  src="assets/imgs/icons/call-btn.svg"
                                  slot="end"
                                />
                                <span className="order-span">
                                  Call Customer
                                </span>
                              </IonButton>
                            </a>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(0)}>
                                                <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Service Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(0) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Services
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_subcategories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event/Occasion
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_categories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">Style</IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                              {booking.listing_style?.title || "N/A"}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Service Amount
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                            <IonLabel className="value-color">
                              £
                              {(
                                (parseFloat(booking.single) || 0) - 
                                ((parseFloat(booking.baf) || 0) - (parseFloat(booking.extra_baf) || 0))
                              ).toFixed(2)}
                            </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {extraPerson.length !== 0 && (
                        <>
                          {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(2)}>
                                                        <IonIcon icon={isAccordionActive(2) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                          <IonLabel className="details-title" slot="start">
                            Extra Person
                          </IonLabel>
                          {/* </IonItem> */}
                          {extraPerson &&
                            extraPerson.map((person: any, i: any) => (
                              <div
                                className={`accordion-content ${
                                  isAccordionActive(2) ? "show" : ""
                                }`}
                              >
                                <IonGrid key={i} className="status3-card">
                                  <IonRow>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Name
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.name}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Type of service
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.style_title}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Service Amount
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        £{person.serviceprice}
                                      </IonLabel>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </div>
                            ))}
                        </>
                      )}
                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(1)}>
                                                <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Booking Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(1) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.address}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Location Type
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.location}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event Date / Time
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.book_date}{" "}
                                <span style={{ color: "#12877733" }}>|</span>{" "}
                                {booking.time}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(3)}>
                                                <IonIcon slot="end" icon={isAccordionActive(3) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Customer Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(3) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Full Name
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.customername}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Email Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.email}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Contact Number
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.mobile}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {/* <div className="the-title p-details-title booking-title-top ion-padding-horizontal"> */}
                      <IonLabel className="details-title">
                        Payment Details
                      </IonLabel>
                      {/* </div> */}
                      <IonCard className="payment-details-card">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="8">
                              <IonLabel className="font-color">
                                Your Price
                                <span className="in-travel-cost">
                                  (Including Travel Cost)
                                </span>
                              </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                              <IonLabel className="p-details-label value-color">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="font-color">
                                Glamdeva Fee
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="p-details-label value-color">
                                £{parseFloat(booking.agency_fee).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow className="p-details-r">
                            <IonCol size="6" col-6>
                              <IonLabel className="balance-pay">
                                Balance to collect
                              </IonLabel>
                            </IonCol>
                            <IonCol
                              className="p-details-last-col"
                              size="6"
                              col-6
                            >
                              <IonLabel className="p-details-label balance-pay">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost) -
                                  parseFloat(booking.agency_fee)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                      {/* <IonGrid>
                                                <IonRow>
                                                    <IonCol size="12" col-12>
                                                        <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                            Go Back
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid> */}
                    </>
                  )}
                </>
              )}
              {booking && (
                <>
                  {booking.status == 1 && (
                    <>
                      {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(0)}>
                                                <IonIcon icon={isAccordionActive(0) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Service Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(0) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Services
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_subcategories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event/Occasion
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.listing_categories.title}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">Style</IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                              {booking.listing_style?.title || "N/A"}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Service Amount
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                                                        <IonLabel className="value-color">
                              £
                              {(
                                (parseFloat(booking.single) || 0) - 
                                ((parseFloat(booking.baf) || 0) - (parseFloat(booking.extra_baf) || 0))
                              ).toFixed(2)}
                            </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {extraPerson.length !== 0 && (
                        <>
                          {/* <IonItem lines="none" detail={false} style={{ marginTop: "0%" }} button className='service-d-title' onClick={() => toggleAccordion(2)}>
                                                        <IonIcon icon={isAccordionActive(2) ? removeOutline : addOutline} slot="end" className='arrowIcon' /> */}
                          <IonLabel className="details-title" slot="start">
                            Extra Person
                          </IonLabel>
                          {/* </IonItem> */}
                          {extraPerson &&
                            extraPerson.map((person, i) => (
                              <div
                                className={`accordion-content ${
                                  isAccordionActive(2) ? "show" : ""
                                }`}
                              >
                                <IonGrid key={i} className="status3-card">
                                  <IonRow>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Name
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.name}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Type of service
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        {person.style_title}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="first-col"
                                      size="6"
                                      col-6
                                    >
                                      <IonLabel className="font-color">
                                        Service Amount
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol className="sec-col" size="6" col-6>
                                      <IonLabel className="value-color">
                                        £{person.serviceprice}
                                      </IonLabel>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </div>
                            ))}
                        </>
                      )}
                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(1)}>
                                                <IonIcon slot="end" icon={isAccordionActive(1) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Booking Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(1) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.address}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Location Type
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.location}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Event Date / Time
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.book_date}{" "}
                                <span style={{ color: "#12877733" }}>|</span>{" "}
                                {booking.time}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {/* <IonItem lines="none" button detail={false} className="service-d-title" onClick={() => toggleAccordion(3)}>
                                                <IonIcon slot="end" icon={isAccordionActive(3) ? removeOutline : addOutline} className='arrowIcon' /> */}
                      <IonLabel className="details-title" slot="start">
                        Customer Details
                      </IonLabel>
                      {/* </IonItem> */}
                      <div
                        className={`accordion-content ${
                          isAccordionActive(3) ? "show" : ""
                        }`}
                      >
                        <IonGrid className="status3-card">
                          <IonRow>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Full Name
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.customername}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Email Address
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.email}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="first-col" size="6" col-6>
                              <IonLabel className="font-color">
                                Contact Number
                              </IonLabel>
                            </IonCol>
                            <IonCol className="sec-col" size="6" col-6>
                              <IonLabel className="value-color">
                                {booking.quote_details.mobile}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                      {/* <div className="the-title p-details-title booking-title-top ion-padding-horizontal"> */}
                      <IonLabel className="details-title">
                        Payment Details
                      </IonLabel>
                      {/* </div> */}
                      <IonCard className="payment-details-card">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="8">
                              <IonLabel className="font-color">
                                Your Price
                                <span className="in-travel-cost">
                                  (Including Travel Cost)
                                </span>
                              </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                              <IonLabel className="p-details-label value-color">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="font-color">
                                Glamdeva Fee
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6" col-6>
                              <IonLabel className="p-details-label value-color">
                                £{parseFloat(booking.agency_fee).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow className="p-details-r">
                            <IonCol size="6" col-6>
                              <IonLabel className="balance-pay">
                                Balance to collect
                              </IonLabel>
                            </IonCol>
                            <IonCol
                              className="p-details-last-col"
                              size="6"
                              col-6
                            >
                              <IonLabel className="p-details-label balance-pay">
                                £
                                {(
                                  parseFloat(booking.subtotal) +
                                  parseFloat(booking.travelcost) -
                                  parseFloat(booking.agency_fee)
                                ).toFixed(2)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                      {/* <IonGrid>
                                                <IonRow>
                                                    <IonCol size="12" col-12>
                                                        <IonButton expand="block" routerLink="/tabs/bookings" className="pickup-btn">
                                                            Go Back
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid> */}
                    </>
                  )}
                </>
              )}
            </div>

            <IonModal
              id="example-modal"
              isOpen={showModal}
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid accept-grid">
                  <IonRow>
                    <IonCol size="12">
                      <IonImg
                        src="assets/imgs/icons/questionMark.svg"
                        alt="questionMark"
                        className="tick-img"
                      ></IonImg>
                      <div className="bookingid-label label-div">
                        <p className="booking-id-chip">
                          {localStorage.getItem("bookingIdPopup")}
                        </p>
                        {bookingStatus == 3 && (
                          <p className="unconfirmed-chip">Unconfirmed</p>
                        )}
                        {bookingStatus == "4" && (
                          <p className="confirmed-chip">Confirmed</p>
                        )}
                        {bookingStatus == "7" && (
                          <p className="modified-chip">Modified</p>
                        )}
                        {bookingStatus == "1" && (
                          <p className="expired-chip">Expired</p>
                        )}
                      </div>
                      <div className="model-sub-div">
                        <IonLabel className="fontName model-sub-title">
                          Are you sure?
                        </IonLabel>
                      </div>
                      <div className="fontName model-sub-div">
                        <IonLabel className="model-note">
                          {" "}
                          If you’re ok with the customer’s requirements, please
                          confirm the booking
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={closeShowModal}
                        className="fontName no-btn"
                        type="submit"
                      >
                        Go Back
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={confirm}
                        className="fontName yes-accept-btn"
                        id="open-customa-dialog"
                        expand="block"
                      >
                        Accept Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              isOpen={showConfirmedModal}
              id="example-modal-confirmed"
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/tick.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName b-cnfmed-title">
                        Booking Confirmed!
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName thanks-cnfmed-title">
                        Thank you for confirming the Booking ID:{" "}
                        {localStorage.getItem("bookingIdPopup")}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        className="fontName booking-details-btn"
                        onClick={() => setShowConfirmedModal(false)}
                        expand="block"
                      >
                        Back to Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              id="example-modal-reject"
              isOpen={showRejectModal}
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="bookingid-label reject-label-div">
                        <p className="reject-booking-id">
                          {localStorage.getItem("bookingIdPopup")}
                        </p>
                      </div>
                      <div>
                        <IonLabel className="font-name-bold reject-f">
                          Rejecting bookings negatively impacts the customer
                          experience and our business.
                        </IonLabel>
                      </div>
                      <div>
                        <IonLabel className="fontName">
                          Frequent rejections will result in:
                        </IonLabel>
                        <ul>
                          <li>Account Suspension</li>
                          <li>Potential Account Closure</li>
                          <li>Negative Reviews</li>
                        </ul>
                        <IonLabel className="fontName reject-sentence">
                          Please accept bookings whenever possible. If there are
                          issues, contact us immediately.
                        </IonLabel>
                      </div>
                      <div>
                        <IonLabel className="font-name-bold reject-f">
                          Are you sure you want to reject this booking?
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonSelect
                          interface="popover"
                          className="fontName form-control-model"
                          placeholder="--select reason--"
                          value={selectedValue}
                          onIonChange={handleSelectChange}
                        >
                          <IonSelectOption value="">
                            Choose Option
                          </IonSelectOption>
                          <IonSelectOption value="I’m already booked">
                            I’m already booked
                          </IonSelectOption>
                          <IonSelectOption value="Too far to travel">
                            Too far to travel
                          </IonSelectOption>
                          <IonSelectOption value="Price too low">
                            Price too low
                          </IonSelectOption>
                          <IonSelectOption value="Client issue">
                            Client issue
                          </IonSelectOption>
                          <IonSelectOption value="Other reason">
                            Other reason
                          </IonSelectOption>
                        </IonSelect>
                        <label
                          className="label-outline-model"
                          htmlFor="textAreaExample"
                        >
                          Reason for Rejection
                        </label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div
                        style={{ marginTop: "4%" }}
                        className="model-container"
                      >
                        <textarea
                          placeholder="Type something here"
                          rows={4}
                          className="fontName form-control-model text-area-css"
                          value={rejecttextarea}
                          onChange={handlesetRejectTextAreaInput}
                        ></textarea>
                        <label
                          className="label-outline-model"
                          htmlFor="textAreaExample"
                        >
                          Notes
                        </label>
                      </div>
                      <div className="fontName emailErrorMsg">
                        {textareaErrorMessage}
                      </div>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={closeRejectModal}
                        className="fontName no-btn"
                        type="submit"
                      >
                        Go Back
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={handleOkayClick}
                        className="fontName yes-reject-btn"
                        id="open-customa-dialog"
                        expand="block"
                      >
                        Reject Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              isOpen={showRejectCnfmModal}
              id="example-modal-confirmed"
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid accept-grid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/warning.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName b-cnfmed-title">
                        Do you want to Reject?
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName thanks-cnfmed-title">
                        Are you sure you want to reject the Booking ID:{" "}
                        {localStorage.getItem("bookingIdPopup")}?
                      </IonLabel>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={closeRejectCnfmModal}
                        className="fontName reject-btn"
                        type="submit"
                      >
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" col-6>
                      <IonButton
                        style={{ width: "100%", margin: "auto" }}
                        onClick={reject}
                        className="fontName booking-details-btn reject-accept-button"
                        id="open-customa-dialog"
                        expand="block"
                      >
                        Okay
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal
              isOpen={showRejectedModal}
              id="example-modal-confirmed"
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/cancel.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName b-cnfmed-title">
                        Booking Rejected!
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName thanks-cnfmed-title">
                        Booking ID: {localStorage.getItem("bookingIdPopup")} has
                        been rejected as per your request.
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/tabs/bookings"}
                        onClick={() => setShowRejectedModal(false)}
                      >
                        <IonButton
                          style={{ width: "100%", margin: "auto" }}
                          className="fontName booking-details-btn"
                          expand="block"
                        >
                          Back to Booking
                        </IonButton>
                      </Link>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonModal
              isOpen={showSuspendedModal}
              id="example-modal-inactive"
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/warning.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName b-cnfmed-title">
                        {supendedTitle}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName thanks-cnfmed-title">
                        {supendedMsg}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className="fontName booking-details-btn"
                        onClick={() => {
                          closeRejectCnfmModal();
                          closeShowModal();
                          setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");
                        }}
                        expand="block"
                      >
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      )}
      <WatiChat />
    </IonPage>
  );
};

export default Viewbooking;
