import {
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./Notificationdetails.css";

import { Network } from '@capacitor/network';
import WatiChat from "../../components/WatiChat";

const Notificationdetail: React.FC = () => {
    const history = useHistory();

    const darkModeValue = localStorage.getItem("darkMode");

    type LocationState = {
        data: any;
    }
    const location = useLocation<LocationState>();
    const message = location.state?.data;
    // const [loading, setLoading] = useState(false);
    // const [backdropVisible, setBackdropVisible] = useState(false);
    // const [message, setMessage] = useState("")
    // setLoading(true);
    // setBackdropVisible(true);

    // setMessage(message);
    // setLoading(false);
    // setBackdropVisible(false);

    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);
    const goBack = () => {
        history.goBack();
    };

    const [networkStatus, setNetworkStatus] = useState<any>(true);
    useEffect(() => {
        if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
            history.push('/login');
          }
        Network.addListener('networkStatusChange', status => {
            setNetworkStatus(status.connected);
        });
    }, []);

    return (
        <IonPage>
            {message &&
                <>
                    {networkStatus == false && darkModeValue == "darkMode" &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="dark-mode-header-toolbar">
                                    <IonButtons slot="start">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/darkBackArrow.svg"
                                                slot="end"
                                            />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center dark-ion-title">Notification Information</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton
                                            className="end-btn"
                                            routerLink="/login"
                                            routerDirection="none"
                                        >
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="dark-mode-ion-content ion-padding">
                                <div className="no-network-div">
                                    <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                                    <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
                                </div>
                            </IonContent>
                        </>
                    }
                    {networkStatus == true &&
                        darkModeValue == "darkMode" &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="dark-mode-header-toolbar">
                                    <IonButtons slot="start">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/darkBackArrow.svg"
                                                slot="end"
                                            />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center dark-ion-title">Notification Information</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton
                                            className="end-btn"
                                            routerLink="/login"
                                            routerDirection="none"
                                        >
                                            {/* <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end"
                /> */}
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="dark-mode-ion-content ion-padding">

                                <div className="section animate__animated animate__fadeInUp">
                                    <IonLabel className="dark-date-label">{message.formatted_date_only} {message.formatted_time_only}</IonLabel>
                                    <IonCard className="dark-card-style">
                                        <p style={{ margin: "0" }}>{message.notification_message}</p>
                                        <IonLabel style={{ float: "right" }}>{message.formatted_time_only}</IonLabel>
                                    </IonCard>
                                </div>
                            </IonContent>
                        </>
                    }

                    {networkStatus == false && darkModeValue == "lightMode" &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="light-mode-header-toolbar">
                                    <IonButtons slot="start">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/backArrow.svg"
                                                slot="end"
                                            />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center notifications-title">Notification Information</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton
                                            className="end-btn"
                                            routerLink="/login"
                                            routerDirection="none"
                                        >
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="notifications ion-padding">
                                <div className="no-network-div">
                                    <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                                    <h6 className='fontName'>Check your internet connection and try again</h6>
                                </div>
                            </IonContent>
                        </>
                    }
                    {networkStatus == true && darkModeValue == "lightMode" &&
                        <>
                            <IonHeader className="ion-no-border">
                                <IonToolbar className="light-mode-header-toolbar">
                                    <IonButtons slot="start">
                                        <IonButton
                                            className="end-btn"
                                            onClick={goBack}
                                        >
                                            <IonIcon
                                                className="fav header-icons"
                                                src="assets/imgs/icons/backArrow.svg"
                                                slot="end"
                                            />
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle className="ion-text-center notifications-title">Notification Information</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton
                                            className="end-btn"
                                            routerLink="/login"
                                            routerDirection="none"
                                        >
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen className="notifications ion-padding">

                                <div className="section animate__animated animate__fadeInUp">
                                    <IonLabel className="date-label">{message.created}</IonLabel>
                                    <IonCard className="card-style">
                                        <p style={{ margin: "0" }}>{message.message}</p>
                                        <IonLabel style={{ float: "right" }}>{message.created}</IonLabel>
                                    </IonCard>
                                </div>
                            </IonContent>
                        </>
                    }
                </>
            }
            <WatiChat />
        </IonPage>
    );
};

export default Notificationdetail;
