import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
import "./Requestmoney.css";

import { Network } from '@capacitor/network';
import WatiChat from "../../components/WatiChat";

const Requestmoney: React.FC = () => {
  const history = useHistory();
  const [unit, setUnit] = useState<string>();
  const goBack = () => {
    history.goBack();
    latestInputOldPassValue.current = "";
    latestInputNewPassValue.current = "";
    latestInputCnfmPassValue.current = "";
    setInputOldPass('');
    setInputNewPass('');
    setInputCnfmPass('');
    setCnfmPassError('');
    setPasswordError("");
  };
  const [inputOldPass, setInputOldPass] = useState('');
  const latestInputOldPassValue = useRef('');

  const [inputNewPass, setInputNewPass] = useState('');
  const latestInputNewPassValue = useRef('');

  const [inputCnfmPass, setInputCnfmPass] = useState('');
  const latestInputCnfmPassValue = useRef('');

  const [checkCnfmPassError, setCheckCnfmPassError] = useState('');

  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [showSuspendedModal, setShowSuspendedModal] = useState(false);

  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);
  const [passwordError, setPasswordError] = useState('');
  const [cnfmPassError, setCnfmPassError] = useState('');

  const [formState, setFormState] = useState({
    oldPass: '',
    oldPassError: '',
    newPass: '',
    newPassError: '',
    cnfmPass: '',
    cnfmPassError: ''
  });

  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      setNetworkStatus(status.connected);
    });
  }, []);

  useEffect(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    }
    setUnauthorizedHandler(() => {
      setSupendedMsg("Your account is inactive, please contact support.");
      setSupendedTitle("Inactive");
      setShowSuspendedModal(true);
    });

    return () => {
      setUnauthorizedHandler(() => {});
    };
  }, []);


  useIonViewWillEnter(() => {
    if (localStorage.getItem("darkMode") == "darkMode") {
      setIsDarkModeActive(true);
    } else {
      setIsDarkModeActive(false);
    }
  }, [])

  const handleOldPassInputChanges = (event: any) => {
    const oldPass = event.target.value;
    setInputOldPass(event.target.value);
    latestInputOldPassValue.current = oldPass;
    // const oldPassPattern = /^[a-zA-Z0-9]+$/;
    // if (oldPass && !oldPassPattern.test(oldPass)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     oldPassError: 'Invalid old password'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     oldPassError: ''
    //   }));
    // }
  };

  const handleNewPassInputChanges = (event: any) => {
    const newPass = event.target.value.replace(/\s+/g, ''); // Remove spaces
    setInputNewPass(newPass);
    latestInputNewPassValue.current = newPass;
    setPasswordError('');
    setCheckCnfmPassError('');
  };

  const handleCnfmPassInputChanges = (event: any) => {
    const cnfmPass = event.target.value.replace(/\s+/g, ''); // Remove spaces
    setInputCnfmPass(cnfmPass);
    latestInputCnfmPassValue.current = cnfmPass;
    setCnfmPassError('');
    setCheckCnfmPassError('');
  };

  const clearPassword = () => {
    setCheckCnfmPassError('');
    latestInputOldPassValue.current = "";
    latestInputNewPassValue.current = "";
    latestInputCnfmPassValue.current = "";
    setInputOldPass('');
    setInputNewPass('');
    setInputCnfmPass('');
    setCnfmPassError('');
    setPasswordError("");
  }

  const submitChangePass = () => {
    // Clear previous error messages
    setPasswordError('');
    setCnfmPassError('');
    setCheckCnfmPassError('');


    // Check if new password matches the confirm password
    if (latestInputNewPassValue.current !== latestInputCnfmPassValue.current) {
      setCheckCnfmPassError('New Password and Confirm password do not match');
      setErrorMessage('New Password and Confirm password do not match');
      setShowErrorToast(true);
      setLoading(false);
      setBackdropVisible(false);
      return;
    }

    if (latestInputNewPassValue.current !== '' && latestInputCnfmPassValue.current !== '') {
      setLoading(true);
      setBackdropVisible(true);
      apiConfig.patch(`/profile/${localStorage.getItem('userId')}`, {
        password: latestInputCnfmPassValue.current
      }, {
        headers: {
          'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.data.status === false) {
            setShowErrorToast(true);
            setErrorMessage(response.data.message);
            setLoading(false);
            setBackdropVisible(false);
          } else {
            setShowToastSuccess(true);
            setSuccessMessage(response.data.message);
            latestInputOldPassValue.current = "";
            latestInputNewPassValue.current = "";
            latestInputCnfmPassValue.current = "";
            setInputOldPass('');
            setInputNewPass('');
            setInputCnfmPass('');
            setCnfmPassError('');
            setPasswordError("");
            history.push('/login');
            setLoading(false);
            setBackdropVisible(false);
          }

          setTimeout(() => {
            if (loading === true) {
              setLoading(false);
              setBackdropVisible(false);
              setErrorMessage("Something went wrong on API please try again");
            }
          }, 10000);
        })
        .catch(error => {
          if (error.code === "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          setLoading(false);
          setBackdropVisible(false);
        });
    } else {
      setErrorMessage('Please enter all the passwords');
      setShowErrorToast(true);
      setLoading(false);
      setBackdropVisible(false);
    }
  };

  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">
                Change Password
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="requestmoney">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">
                Change Password
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="requestmoney">
            <div className="bg-fp"></div>
            <div className="gradient-overlay m-d-none"></div>
            <div className="web-fp">
              <IonImg className="change-password-img" src="assets/imgs/images/changePassword.svg" alt=""></IonImg>
              <IonLabel className="change-password-label">Your new password must be different from previously used password.</IonLabel>

              <div style={{ marginTop: "15%" }} className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow>
                    {/* <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp fontName'
                          value={inputOldPass}
                          onChange={e => handleOldPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp" htmlFor="textAreaExample">Old Password</label>
                      </div>
                      {formState.oldPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.oldPassError}</span>}
                    </IonCol> */}
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp fontName'
                          value={inputNewPass}
                          onChange={e => handleNewPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp" htmlFor="textAreaExample">New Password</label>

                      </div>
                      {passwordError && (<span className='fontName emailErrorMsg'>{passwordError}</span>)}
                      {formState.newPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.newPassError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp fontName'
                          value={inputCnfmPass}
                          onChange={e => handleCnfmPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp" htmlFor="textAreaExample">Confirm Password</label>
                      </div>
                      {cnfmPassError && (<span className='fontName emailErrorMsg'>{cnfmPassError}</span>)}
                      {/* {formState.cnfmPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.cnfmPassError}</span>} */}
                      <span className='fontName emailErrorMsg'>{checkCnfmPassError}</span>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonLabel className="edit-cancel" onClick={clearPassword} >Clear Password</IonLabel>
                      <IonButton style={{ marginTop: "5%" }} expand="block" onClick={submitChangePass} className="save-btn-profile">
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no"); }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger" />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
          </IonContent></>
      }
      {networkStatus == false && isDarkModeActive == true &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">
              Change Password
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="dark-profile">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == true &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">
              Change Password
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="dark-profile">
            <div>
              <IonImg className="change-password-img" src="assets/imgs/images/darkChangePassword.svg" alt=""></IonImg>
              <IonLabel className="dark-change-password-label">Your new password must be different from previously used password.</IonLabel>
            </div>
            <div style={{ marginTop: "15%" }} className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model-fp fontName'
                        value={inputOldPass}
                        onChange={e => handleOldPassInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">Old Password</label>
                    </div>
                    {formState.oldPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.oldPassError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model-fp fontName'
                        value={inputNewPass}
                        onChange={e => handleNewPassInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">New Password</label>
                      {passwordError && (<span className='fontName emailErrorMsg'>{passwordError}</span>)}
                    </div>
                    {formState.newPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.newPassError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model-fp fontName'
                        value={inputCnfmPass}
                        onChange={e => handleCnfmPassInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">Confirm Password</label>
                      {cnfmPassError && (<span className='fontName emailErrorMsg'>{cnfmPassError}</span>)}
                    </div>
                    {/* {formState.cnfmPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.cnfmPassError}</span>} */}
                    <span className='fontName emailErrorMsg'>{checkCnfmPassError}</span>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonButton expand="block" onClick={submitChangePass} className="submit-fp">
                      Submit
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger" />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no"); }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent></>
      }
      <WatiChat />
    </IonPage>
  );
};

export default Requestmoney;
