import {
  IonButton,
  IonCard,
  IonContent,
  IonGrid,
  IonPage,
  IonRow
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Successmodal.css";
import { useHistory } from "react-router";

const Successmodal: React.FC<{ onClose?: any }> = ({ onClose }) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/login');
    }, 3000);
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen >
        <IonGrid style={{ height: "100%" }}>
          <IonRow
            className="ion-justify-content-center ion-align-items-center"
            style={{ height: "100%" }}
          >
            <div className="holder ion-text-center">
              <IonCard className='card-success'>
                <svg
                  id="successAnimation"
                  className="animated"
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 70 70"
                >
                  <path
                    id="successAnimationResult"
                    fill="#D8D8D8"
                    d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
                  />
                  <circle
                    id="successAnimationCircle"
                    cx="35"
                    cy="35"
                    r="24"
                    stroke="#979797"
                    strokeWidth={2}
                    strokeLinecap={'round'}
                    fill="transparent"
                  />
                  <polyline
                    id="successAnimationCheck"
                    stroke="#979797"
                    strokeWidth={2}
                    points="23 34 34 43 47 27"
                    fill="transparent"
                  />
                </svg>

                <h2 className="successmodal-h2">Thank you for signing up, Welcome to Glamdeva!. Please click the activation link sent on your email, to verify your account. One of our team members will be in touch with you shortly and go through the on-boarding process.</h2>
                <br />
                <br />
                <IonButton
                  routerLink="/login"
                  routerDirection="none" className='fontName bc-to-login booking-details-btn' expand="block">
                  Back to Login
                </IonButton>
              </IonCard>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Successmodal;
