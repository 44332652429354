import axios, { AxiosHeaders } from 'axios';

let handleUnauthorized: (() => void) | null = null;

export const setUnauthorizedHandler = (handler: () => void) => {
  handleUnauthorized = handler;
};

const apiConfig = axios.create({
  baseURL: 'https://api.glamdeva.com/partner/v1',
});

apiConfig.interceptors.request.use(
  (config) => {
    const tokenType = localStorage.getItem('tokenType');
    const accessToken = localStorage.getItem('accessToken');

    const endpoint = new URL(config.url || "", apiConfig.defaults.baseURL).pathname;

    if (['/login', '/auto_login'].includes(endpoint)) {
      config.headers = new AxiosHeaders();
    } else {
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }

      config.headers.set('Accept', 'application/json');

      if (tokenType && accessToken) {
        config.headers.set('Authorization', `${tokenType} ${accessToken}`);
      }

      if (config.data instanceof FormData) {
        config.headers.set('Content-Type', 'multipart/form-data');
      } else {
        config.headers.set('Content-Type', 'application/json');
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && handleUnauthorized) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);

export default apiConfig;