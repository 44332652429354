import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
  SearchbarChangeEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState, useRef } from "react";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
import "./Wallet.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { Network } from "@capacitor/network";
import { Filesystem, Directory } from "@capacitor/filesystem";
import WatiChat from "../../components/WatiChat";
import React from "react";
import { Autoplay, Pagination } from "swiper";
import { log } from "console";

const Wallet: React.FC = () => {

  const fileInputRef = useRef<HTMLInputElement>(null);

  const darkModeValue = localStorage.getItem("darkMode");
  const history = useHistory();
  const [activeSegment, setActiveSegment] = useState<string>("activeServices");
  const [products, setProducts] = useState<Array<any>>([]);
  const [services, setServices] = useState<Array<any>>([]);
  const [activeServices, setActiveServices] = useState<Array<any>>([]);
  const [inactiveServices, setInactiveServices] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [isProductListLoading, setIsProductListLoading] = useState(true);
  const [InactiveProducts, setInactiveProducts] = useState<Array<any>>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInactiveQuery, setSearchInactiveQuery] = useState("");

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [showImgViewModal, setShowImgViewModal] = useState(false);


type ImageData = {
  url: string;
  imageid?: number;
  is_feature_image?: number;
};

type StyleData = {
  service_id?: number;
}


const [imageData, setImageData] = useState<ImageData | null>(null);
const [serviceId, setServiceId] = useState<StyleData | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState("");
  const [supendedTitle, setSupendedTitle] = useState("");
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [editDetailsCat, setEditDetailsCat] = useState<any>();
  const [editDetailsSubCat, setEditDetailsSubCat] = useState<any>();
  const [editDetailsStyle, setEditDetailsStyle] = useState<any>();

  const [editFormData, setEditFormData] = useState({
    service_id: "",
    price: "0.00",
    costmiles: "0.00",
    minmiles: "0.00",
    // "status": 0,
    catid: "",
    subcatid: "",
    style_id: "",
    subcat_title: "",
    userid: "",
    images: [{ url: "" }],
  });

  const modal = useRef<HTMLIonModalElement>(null);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [capturedImages, setCapturedImages] = useState<Array<any>>([]);
  const [toggleStatus, setToggleStatus] = useState(Boolean);

  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useIonViewWillEnter(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    } else {
    servicesCountFunc();
    getServices();
    }
  }, []);

  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      setNetworkStatus(status.connected);
    });
    // if (activeSegment == "activeServices") {
    // activeService();
    //   setSearchQuery('');
    //   setSearchInactiveQuery('');
    // } else {
    //   inactiveService();
    //   setSearchQuery('');
    //   setSearchInactiveQuery('');
    // }
    // checkAndroidVersion();
    // generateItems();
  }, []);

  useEffect(() => {
   
    setUnauthorizedHandler(() => {
      setSupendedMsg("Your account is inactive, please contact support.");
      setSupendedTitle("Inactive");
      setShowSuspendedModal(true);
    });

    return () => {
      setUnauthorizedHandler(() => {});
    };
  }, []);

  const getServices = () => {
    setProducts([]);
    setLoading(true);
    setBackdropVisible(true);

    apiConfig
      .get(
        `/newservices?userid=${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        servicesCountFunc();

        if (res.data.data.active_services.length === 0 && res.data.data.inactive_services.length === 0) {
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(true);
        } else {
          setServices(res.data.data);
          setActiveServices(res.data.data.active_services);
          setInactiveServices(res.data.data.inactive_services);
          setBackdropVisible(false);
          setLoading(false);
          setIsProductListLoading(false);
        }

        setTimeout(() => {
          if (loading === true) {
            setLoading(false);
            setBackdropVisible(false);
          }
        }, 10000);
      })
      .catch((error) => {
        setLoading(false);
        setIsProductListLoading(false);
      });
  };

  const handlePictureUpload = (serviceId: string, styleId: string) => {
  
    // Create the input element within the user gesture (e.g., onClick or onTap)
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
  
    input.onchange = async (event) => {
      try {
        const target = event.target as HTMLInputElement;
        if (!target.files || target.files.length === 0) {
          return;
        }
  
        const files = Array.from(target.files);
        const newImageUrls: { url: string }[] = [];
  
        for (const file of files) {
          const dataUrl = await readFileAsDataUrl(file);
          newImageUrls.push({ url: dataUrl });
        }
  
        // Update images in the UI
        handleUpdateImages(styleId, newImageUrls);
  
        setActiveServices((prevServices) =>
          prevServices.map((service) => {
            if (Number(service.service_id) === Number(serviceId)) {
              return {
                ...service,
                subcategory: service.subcategory.map((subCat: { styles: any[] }) => {
                  return {
                    ...subCat,
                    styles: subCat.styles.map((style: { id: string; images: any }) => {
                      if (style.id === styleId) {
                        const updatedImages = [...(style.images || []), ...newImageUrls];
  
                        return {
                          ...style,
                          images: updatedImages,
                        };
                      }
                      return style;
                    }),
                  };
                }),
              };
            }
            return service;
          })
        );
      } catch (error) {
        console.error("Error processing images:", error);
      } finally {
      }
    };
  
    input.focus();
    setTimeout(() => {
      input.click();
    }, 100);
  };
  
  // Helper function to read the file as a data URL
  const readFileAsDataUrl = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, serviceId: string, styleId: string) => {
  
    try {
      const target = event.target;
      if (!target.files || target.files.length === 0) {
        return;
      }
  
      const files = Array.from(target.files);
      const newImageUrls: { url: string }[] = [];
  
      for (const file of files) {
        const dataUrl = await readFileAsDataUrl(file);
        newImageUrls.push({ url: dataUrl });
      }
  
      handleUpdateImages(styleId, newImageUrls);
  
      setActiveServices((prevServices) =>
        prevServices.map((service) => {
          if (Number(service.service_id) === Number(serviceId)) {
            return {
              ...service,
              subcategory: service.subcategory.map((subCat: { styles: any[] }) => {
                return {
                  ...subCat,
                  styles: subCat.styles.map((style: { id: string; images: any }) => {
                    if (style.id === styleId) {
                      const updatedImages = [...(style.images || []), ...newImageUrls];
                      return { ...style, images: updatedImages };
                    }
                    return style;
                  }),
                };
              }),
            };
          }
          return service;
        })
      );
    } catch (error) {
      console.error("Error processing images:", error);
    } finally {
    }
  };

  const handleUpdateImages = (serviceId: string, images: { url: string }[]) => {
    
    setLoading(true);
    setBackdropVisible(true);

    apiConfig
      .post(
        `/new-services-details-update`,
        {
          service_id: serviceId,
          images: images,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // setActiveCount(res.data.data.length);
        if (res.data.status === true) {
          setLoading(false);
          setBackdropVisible(false);
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          getServices();
          setShowEditModal(false);
          // setIsProductListLoading(true);
        } else {
          setShowToast(true);
          setErrorMessage(res.data.message);
          // setBackdropVisible(false);
          // setLoading(false);
          // setIsProductListLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setBackdropVisible(false);
        if (error.response.status === 500) {
          setShowToast(true);
          setErrorMessage(error.response.data.message);
        }

      });
  };

  const removeImage = (
    serviceId: string,
    styleId: string,
    active_status: any,
    index: any
  ) => {
    setActiveServices((prevServices) => {
      return prevServices.map((service) => {
        if (service.service_id === serviceId) {
          return {
            ...service,
            subcategory: service.subcategory.map(
              (subCat: { styles: any[] }) => {
                return {
                  ...subCat,
                  styles: subCat.styles.map(
                    (style: { id: string; images: any[] }) => {
                      if (style.id === styleId) {
                        const newImages = style.images.filter(
                          (_: any, i: number) => i !== index
                        );
                        // handleUpdateImages(serviceId, active_status, newImages);
                        return { ...style, images: newImages };
                      }
                      return style;
                    }
                  ),
                };
              }
            ),
          };
        }
        return service;
      });
    });
  };

  
  const imageView = (img: any, style: any) => {
    
    setShowImgViewModal(true);
    setImageData(img);
    setServiceId(style.service_id);
  }

  const markAsFeaturedImage = ( imgId: any ) => {

    setLoading(true);
    setBackdropVisible(true);

    apiConfig
      .post(
        `/set-feature-image`,
        {
          "service_id": serviceId,
          "imageid": imgId
      },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === true) {
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          setShowImgViewModal(false);
          setLoading(false);
          getServices();
          setShowEditModal(false);
        } else {
          setLoading(false);
          setShowToast(true);
          setErrorMessage(res.data.message);
        }
        setTimeout(() => {
          if (loading === true) {
            setLoading(false);
            setBackdropVisible(false);
          }
        }, 10000);
      })
      .catch((error) => {
        setLoading(false);
        setBackdropVisible(false);
        if (error.response.status === 429) {
          setShowToast(true);
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const deleteImage = (imgId: any) => {

    setLoading(true);
    setBackdropVisible(true);

    apiConfig
      .post(
        `/delete-service-image`,
        {
          imageid: imgId,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === true) {
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          getServices();
          setShowEditModal(false);
        } else {
          setShowToast(true);
          setErrorMessage(res.data.message);
        }
        setTimeout(() => {
          if (loading === true) {
            setLoading(false);
            setBackdropVisible(false);
          }
        }, 10000);
      })
      .catch((error) => {
        setLoading(false);
        setBackdropVisible(false);
        if (error.response.status === 429) {
          setShowToast(true);
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const editModelFunction = (subcat: any, style: any, user: any) => {
    setShowEditModal(true);

    setEditDetailsCat(user);
    setEditDetailsSubCat(subcat);
    setEditDetailsStyle(style);

    setEditFormData({
      ...editFormData,
      ["service_id"]: style.id,
      ["price"]: style.price,
      ["costmiles"]: style.costmiles,
      ["minmiles"]: style.minmiles,
      // ['status']: style.status,
      ["catid"]: style.catid,
      ["subcatid"]: style.subcatid,
      ["style_id"]: style.style_id,
      ["subcat_title"]: style.subcat_title,
      ["userid"]: `${localStorage.getItem("userId")}`,
      ["images"]: style.images,
    });
  };

  const updateService = (style: any, field: any) => {
    if (
      editFormData.price !== "" &&
      editFormData.costmiles !== "" &&
      editFormData.minmiles !== ""
    ) {
      setLoading(true);
      setBackdropVisible(true);
      if (style.id == null) {
        apiConfig
          .post(`/services-details-update`, editFormData, {
            headers: {
              Authorization: `${localStorage.getItem(
                "tokenType"
              )} ${localStorage.getItem("accessToken")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            setActiveCount(res.data.data.length);
            if (res.data.status == true) {
              setLoading(false);
              setBackdropVisible(false);
              if (field == "active") {
                activeService();
              } else {
                inactiveService();
              }
              setShowEditModal(false);
              setIsProductListLoading(true);
            } else {
              setBackdropVisible(false);
              setLoading(false);
              setIsProductListLoading(false);
              // setDisplayIndex(10);
            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch((error) => {
            setLoading(false);
            setIsProductListLoading(false);
          });
      } else {
        apiConfig
          .post(
            `/services-details-update`,
            {
              service_id: style.id,
              price: editFormData.price,
              costmiles: editFormData.costmiles,
              minmiles: editFormData.minmiles,
            },
            {
              headers: {
                Authorization: `${localStorage.getItem(
                  "tokenType"
                )} ${localStorage.getItem("accessToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setShowToastSuccess(true);
            setSuccessMessage(res.data.message);
            if (res.data.status == true) {
              setLoading(false);
              setBackdropVisible(false);
              if (field == "active") {
                activeService();
              } else {
                inactiveService();
              }
              setShowEditModal(false);
              setIsProductListLoading(true);
            } else {
              setBackdropVisible(false);
              setLoading(false);
              setIsProductListLoading(false);
              // setDisplayIndex(10);
            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch((error) => {
            setLoading(false);
            setIsProductListLoading(false);
          });
      }
    } else {
      setErrorMessage("Please enter all the inputs");
      setShowToast(true);
    }
  };

  const handleToggleChange = (event: any, style: any) => {

    const isChecked = event.detail.checked == true ? 1 : 2;

    setLoading(true);
    setBackdropVisible(true);

    if (!style.service_id) {
      apiConfig
        .post(
          `/new-services-details-update`,
          {
            active_status: isChecked,
            price: style.price,
            costmiles: style.costmiles,
            minmiles: style.minmiles,
            catid: style.catid,
            style_id: style.id,
            subcatid: style.subcatid,
            subcat_title: style.title,
            userid: localStorage.getItem("userId"),
          },
          {
            headers: {
              Authorization: `${localStorage.getItem(
                "tokenType"
              )} ${localStorage.getItem("accessToken")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          setShowEditModal(false);
          getServices();
          // setLoading(false);
          // setBackdropVisible(false);

          // setTimeout(() => {
          //   if (loading == true) {
          //     setLoading(false);
          //     setBackdropVisible(false);
          //     // setErrorMessage("Something went wrong on API please try again");
          //     // setShowToast(true);
          //   }
          // }, 10000);
        })
        .catch((error) => {
          setLoading(false);
          setBackdropVisible(false);
          if (error.response.status === 500) {
            setShowToast(true);
            setErrorMessage(error.response.data.message);
          }
  
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          // setLoading(false);
          // setBackdropVisible(false);
          setIsProductListLoading(false);
        });
    } else {
      apiConfig
        .post(
          `/new-services-details-update`,
          {
            active_status: isChecked,
            service_id: style.service_id,
          },
          {
            headers: {
              Authorization: `${localStorage.getItem(
                "tokenType"
              )} ${localStorage.getItem("accessToken")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          setShowEditModal(false);
          getServices();
          // setLoading(false);
          // setBackdropVisible(false);

          // setTimeout(() => {
          //   if (loading == true) {
          //     setLoading(false);
          //     setBackdropVisible(false);
          //     // setErrorMessage("Something went wrong on API please try again");
          //     // setShowToast(true);
          //   }
          // }, 10000);
        })
        .catch((error) => {
          setLoading(false);
          setBackdropVisible(false);
          if (error.response.status === 500) {
            setShowToast(true);
            setErrorMessage(error.response.data.message);
          }
  
          
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(false);
        });
    }
  };

  const servicesCountFunc = () => {
    apiConfig
      .get(
        `/service_count?userid=${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setActiveCount(res.data.data.active_count);
        setInactiveCount(res.data.data.inactive_count);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
      });
  };

  const setFeaturedImage = () => {
    apiConfig
    .get(
      `/set-feature-image`,
      {
        headers: {
          Authorization: `${localStorage.getItem(
            "tokenType"
          )} ${localStorage.getItem("accessToken")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      setActiveCount(res.data.data.active_count);
      setInactiveCount(res.data.data.inactive_count);
    })
    .catch((error) => {
      if (error.code == "ERR_NETWORK") {
        setNetworkStatus(false);
      } else {
        setNetworkStatus(true);
      }
    });
  }
  const activeService = () => {
    setProducts([]);
    setLoading(true);
    setBackdropVisible(true);
    apiConfig
      .get(
        `/services?active=true&userid=${localStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        servicesCountFunc();

        if (res.data.data.length === 0) {
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(true);
        } else {
          setProducts(res.data.data);
          setBackdropVisible(false);
          setLoading(false);
          setIsProductListLoading(false);
          // setDisplayIndex(10);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        setLoading(false);
        setIsProductListLoading(false);
      });
  };

  const inactiveService = () => {
    setLoading(true);
    setBackdropVisible(true);
    setInactiveProducts([]);
    setProducts([]);
    apiConfig
      .get(
        `/services?active=false&userid=${localStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        servicesCountFunc();

        if (res.data.data.length === 0) {
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(true);
        } else {
          setInactiveProducts(res.data.data);
          setBackdropVisible(false);
          setLoading(false);
          setIsProductListLoading(false);
          // setInactiveDisplayIndex(10);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
        setIsProductListLoading(false);
      });
  };

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    subCatId: string,
    styleId: string
  ) => {
    const value = e.target.value;
    handleEditValues(`${subCatId}-${styleId}`, value);
  };

  const validatePrice = (
    e: React.FocusEvent<HTMLInputElement>,
    subCatId: string,
    styleId: string
  ) => {
    const value = e.target.value.trim();
    if (value === "") {
      handleEditValues(`${subCatId}-${styleId}`, "");
    } else if (!isNaN(value as any) && !isNaN(parseFloat(value))) {
      handleEditValues(`${subCatId}-${styleId}`, parseFloat(value));
    }
  };
  const handleEditValues = (key: string, value: any) => {
    setEditFormData((prevData) => ({
      ...prevData,
      [key]: { price: value },
    }));
  };

  const activeServiceSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setSearchQuery(value ?? "");
  };
  const inActiveServiceSearch = (
    e: CustomEvent<SearchbarChangeEventDetail>
  ) => {
    const value = e.detail.value;
    setSearchInactiveQuery(value ?? "");
  };

  const segmentChanged = (e: any) => {
    setActiveSegment(e.detail.value);
    if (e.detail.value == "activeServices") {
      activeService();
      setSearchQuery("");
      setSearchInactiveQuery("");
    } else {
      inactiveService();
      setSearchQuery("");
      setSearchInactiveQuery("");
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const viewService = (e: any) => {
    localStorage.setItem("productId", e);
  };

  const handleActiveRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      activeService();
      event.detail.complete();
    }, 1000);
  };

  const handleInactiveRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      inactiveService();
      event.detail.complete();
    }, 1000);
  };

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">Services</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="no-network-div">
              <IonImg
                className="no-network-img"
                src="assets/imgs/images/darkNoNetwork.svg"
              ></IonImg>
              <h6 style={{ color: "#CCC" }} className="fontName">
                Check your internet connection and try again
              </h6>
            </div>
          </IonContent>
        </>
      )}
      {networkStatus == true && darkModeValue == "darkMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">Services</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            {activeSegment === "activeServices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleActiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {activeSegment === "inactiveservices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleInactiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {/* <div className="search-holder">
              {activeSegment === "activeServices" && (
                <IonSearchbar
                  placeholder="Search Active Services"
                  style={{ width: "95%" }}
                  className="dark-search-bar"
                  mode="ios"
                  debounce={1000}
                  onIonChange={activeServiceSearch}
                ></IonSearchbar>
              )}
              {activeSegment === "inactiveservices" && (
                <IonSearchbar
                  style={{ width: "95%" }}
                  className="dark-search-bar"
                  placeholder='Search Inactive Service'
                  mode="ios"
                  debounce={1000}
                  onIonChange={inActiveServiceSearch}
                ></IonSearchbar>
              )}
            </div> */}
            <div className="details">
              {/* <div className="segment-holder segment-width">
                <IonSegment
                  onIonChange={(e) => segmentChanged(e)}
                  value={activeSegment as any}
                >
                  <IonSegmentButton value={"activeServices"} className="dark-custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Active Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{activeCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton value={"inactiveservices"} className="dark-custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Inactive Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{inactiveCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div> */}
              {activeSegment === "activeServices" ? (
                <div className="send-money-holder animate__animated animate__fadeInRight animate__slow">
                  <div className="the-title p-add-title ion-padding-horizontal">
                    <IonLabel className="dark-dash-main-title">
                      Add new products / Services
                    </IonLabel>
                  </div>

                  <div className="contact-holder">
                    <Link
                      className="link-decoration"
                      to={{ pathname: "/addproduct" }}
                    >
                      {/* <IonButton routerLink="/addproduct" routerDirection="none"> */}
                      <div className="recip-card ion-text-center">
                        <div className="add-btn-holder">
                          <img src="assets/imgs/icons/plus.svg" alt="" />
                        </div>
                        <p className="ion-text-wrap">
                          Add your <br /> products
                        </p>
                      </div>
                      {/* </IonButton> */}
                    </Link>
                    {products.map((user, i) => (
                      <div key={i} className="recip-card ion-text-center">
                        <IonAvatar>
                          <img
                            src={
                              user.image
                                ? user.image
                                : "assets/imgs/images/no-image.jpg"
                            }
                            alt=""
                          />
                        </IonAvatar>
                        <h6 className="ion-text-wrap slide-img-title fontName scroll-image-title text-ellipsis">
                          {user.title}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {activeSegment === "activeServices" ? (
                <div className="dark-services-empty-div">
                  <span> </span>
                </div>
              ) : (
                <div></div>
              )}
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "activeServices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg
                                className="no-data-img"
                                src="assets/imgs/images/no-data.svg"
                              ></IonImg>
                              <h6
                                style={{
                                  color: "#CCCCCC",
                                  textAlign: "center",
                                  marginTop: "12%",
                                  fontSize: "16px",
                                }}
                                className="fontName nocardh6"
                              >
                                Sorry, there are no Active List for you
                              </h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredActiveList = products
                              // .slice(0, displayIndex)
                              .filter(
                                (result) =>
                                  (result.title &&
                                    result.title
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())) ||
                                  (result.description &&
                                    result.description
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase()))
                              );
                            if (
                              filteredActiveList.length === 0 &&
                              searchQuery !== ""
                            ) {
                              return (
                                <IonCol>
                                  <div className="dark-fontName nocard">
                                    <IonImg
                                      className="no-data-img"
                                      src="assets/imgs/images/no-data.svg"
                                    ></IonImg>
                                    <h6
                                      style={{
                                        color: "#CCCCCC",
                                        textAlign: "center",
                                        marginTop: "12%",
                                        fontSize: "16px",
                                      }}
                                      className="dark-fontName nocardh6"
                                    >
                                      Sorry, there are no Active Lists for you
                                    </h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredActiveList.map((user, i) => (
                                <IonCol
                                  key={i}
                                  size="6"
                                  size-md="3"
                                  className="col-p0"
                                >
                                  <Link
                                    className="link-decoration"
                                    onClick={() => viewService(user.id)}
                                    to={"/addincome"}
                                  >
                                    <IonGrid className="dark-fontName p-0 productGrid">
                                      <IonRow>
                                        <IonCol className="col-p0" size="12">
                                          <div className="dark-fontName image-containers">
                                            <img
                                              className="product-img"
                                              src={
                                                user.image
                                                  ? user.image
                                                  : "assets/imgs/images/no-image.jpg"
                                              }
                                              alt="Product Image"
                                            />
                                          </div>
                                          <div className="dark-image-title-card">
                                            <IonLabel className="dark-fontName dark-image-title text-ellipis">
                                              {user.title}
                                            </IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "inactiveservices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg
                                className="no-data-img"
                                src="assets/imgs/images/no-data.svg"
                              ></IonImg>
                              <h6
                                style={{
                                  color: "#CCCCCC",
                                  textAlign: "center",
                                  marginTop: "12%",
                                  fontSize: "16px",
                                }}
                                className="fontName nocardh6"
                              >
                                Sorry, there are no Inactive List for you
                              </h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredInactiveList = InactiveProducts
                              // .slice(0, inactiveDisplayIndex)
                              .filter(
                                (result) =>
                                  result.title
                                    .toLowerCase()
                                    .includes(
                                      searchInactiveQuery.toLowerCase()
                                    ) ||
                                  result.description
                                    .toLowerCase()
                                    .includes(searchInactiveQuery.toLowerCase())
                              );
                            if (
                              filteredInactiveList.length === 0 &&
                              searchInactiveQuery !== ""
                            ) {
                              return (
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg
                                      className="no-data-img"
                                      src="assets/imgs/images/no-data.svg"
                                    ></IonImg>
                                    <h6
                                      style={{
                                        color: "#CCCCCC",
                                        textAlign: "center",
                                        marginTop: "12%",
                                        fontSize: "16px",
                                      }}
                                      className="fontName nocardh6"
                                    >
                                      Sorry, there are no Inactive List for you
                                    </h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredInactiveList.map((user, i) => (
                                <IonCol
                                  key={i}
                                  size-md="3"
                                  size="6"
                                  className="col-p0"
                                >
                                  <Link
                                    className="link-decoration"
                                    onClick={() => viewService(user.id)}
                                    to={"/addincome"}
                                  >
                                    <IonGrid className="fontName p-0 productGrid">
                                      <IonRow>
                                        <IonCol
                                          col-12
                                          size="12"
                                          className="col-p0"
                                        >
                                          <div className="fontName image-containers">
                                            <img
                                              className="product-img"
                                              src={
                                                user.image
                                                  ? user.image
                                                  : "assets/imgs/images/no-image.jpg"
                                              }
                                              alt="Product Image"
                                            />
                                          </div>
                                          <div className="dark-image-title-card">
                                            <IonLabel className="dark-fontName dark-image-title text-ellipis">
                                              {user.title}
                                            </IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal
              isOpen={showSuspendedModal}
              id="dark-example-modal-inactive"
              backdropDismiss={false}
            >
              <IonContent className="dark-model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/warning.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-b-cnfmed-title">
                        {supendedTitle}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName dark-thanks-cnfmed-title">
                        {supendedMsg}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className="fontName booking-details-btn"
                        onClick={() => {setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");}}
                        expand="block"
                      >
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      )}
      {networkStatus == false && darkModeValue == "lightMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ fontSize: "16px" }} className="light-ion-title">Services</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="wallet">
            <div className="no-network-div">
              <IonImg
                className="no-network-img"
                src="assets/imgs/images/noNetwork.svg"
              ></IonImg>
              <h6 className="fontName">
                Check your internet connection and try again
              </h6>
            </div>
          </IonContent>
        </>
      )}
      {networkStatus == true && darkModeValue == "lightMode" && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ fontSize: "16px" }} className="light-ion-title font-name-bold">
                Services
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="wallet">
            {activeSegment === "activeServices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleActiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {/* {activeSegment === "inactiveservices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleInactiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )} */}
            <div className="search-holder">
              {/* {activeSegment === "activeServices" && (
                <IonSearchbar
                  placeholder="Search Active Services"
                  style={{ width: "95%" }}
                  className="search-bar"
                  mode="ios"
                  debounce={1000}
                  onIonChange={activeServiceSearch}
                ></IonSearchbar>
              )}
              {activeSegment === "inactiveservices" && (
                <IonSearchbar
                  style={{ width: "95%" }}
                  className="search-bar"
                  placeholder='Search Inactive Service'
                  mode="ios"
                  debounce={1000}
                  onIonChange={inActiveServiceSearch}
                ></IonSearchbar>
              )} */}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                {/* <IonSegment
                  onIonChange={(e) => segmentChanged(e)}
                  value={activeSegment as any}
                >
                  <IonSegmentButton value={"activeServices"} className="custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Active Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{activeCount}</span></h6>

                  </IonSegmentButton>
                  <IonSegmentButton value={"inactiveservices"} className="custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Inactive Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{inactiveCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment> */}
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div className="title-div">
                        <IonImg
                          className="service-dot-icon"
                          src="assets/imgs/icons/active.svg"
                        ></IonImg>
                        <h6 className="font-name-bold serices-title">
                          My Active Services ({activeCount})
                        </h6>
                      </div>
                      {services && activeServices.length > 0 ? (
                        <div className="the-list">
                          <IonGrid>
                            <IonRow>
                              {isProductListLoading ? (
                                <IonCol size="12">
                                  <div className="fontName nocard">
                                    <IonImg
                                      className="no-data-img"
                                      src="assets/imgs/images/no-data.svg"
                                    ></IonImg>
                                    <h6
                                      style={{
                                        color: "#515151",
                                        textAlign: "center",
                                        marginTop: "11%",
                                      }}
                                      className="fontName nocardh6"
                                    >
                                      Sorry, there are no Active List for you
                                    </h6>
                                  </div>
                                </IonCol>
                              ) : (
                                activeServices.map((user, i) => (
                                  <React.Fragment key={i}>
                                    <IonCol size="12">
                                      <IonLabel className="font-name-bold image-title">
                                        {user.title}
                                      </IonLabel>
                                    </IonCol>
                                    {user.subcategory.map(
                                      (subCat: any, j: any) =>
                                        subCat.styles.map(
                                          (style: any, k: any) => {
                                            return (
                                           <IonCol key={`${j}-${k}-${style.service_id}`} size-md="6" size-lg="3" size="12">
                                              <IonGrid className="overall-list active-card-p">
                                                <IonRow>
                                                  <IonCol
                                                    size-md="8"
                                                    size="6"
                                                    className="second-col"
                                                  >
                                                    <div className="d-flex">
                                                      <IonLabel className="font-name-bold style-first-title service-title-pwa">
                                                        {style.title}
                                                      </IonLabel>
                                                      <IonLabel className="sub-cat-title">
                                                        {subCat.title}
                                                      </IonLabel>
                                                    </div>
                                                  </IonCol>
                                                  <IonCol size-md="4" size="6">
                                                    <div className="action-div">
                                                      <h6 className="fontName status-title">
                                                        Active
                                                      </h6>
                                                      <IonToggle
                                                        className="toggleActive ion-float-right"
                                                        slot="end"
                                                        checked={
                                                          style.active_status ==
                                                          "1"
                                                            ? true
                                                            : false
                                                        }
                                                        onIonChange={(e) =>
                                                          handleToggleChange(
                                                            e,
                                                            style
                                                          )
                                                        }
                                                      ></IonToggle>
                                                    </div>
                                                  </IonCol>
                                                  </IonRow>
                                                  <IonRow>
                                                  <IonCol size-md="10" size="10">
                                                    <Swiper
                                                      modules={[
                                                        // Autoplay,
                                                        Pagination,
                                                      ]}
                                                      // autoplay={true}
                                                      spaceBetween={10}
                                                      breakpoints={{
                                                        320: {
                                                          slidesPerView: 5,
                                                          spaceBetween: 2,
                                                        },
                                                        768: {
                                                          slidesPerView: 3,
                                                          spaceBetween: 20,
                                                        },
                                                        1024: {
                                                          slidesPerView: 4,
                                                          spaceBetween: 30,
                                                        },
                                                      }}
                                                    >
                                                      {style.images &&
                                                      style.images.length >
                                                        0 ? (
                                                        style.images
                                                          .filter(
                                                            (img: any) =>
                                                              img !== null &&
                                                              img.url
                                                          )
                                                          .map(
                                                            (
                                                              img: {
                                                                is_feature_image: number;
                                                                imageid: any;
                                                                url:
                                                                  | string
                                                                  | undefined;
                                                              },
                                                              i:
                                                                | React.Key
                                                                | null
                                                                | undefined
                                                            ) => (
                                                              <SwiperSlide
                                                              className="service-swiper"
                                                                key={i}
                                                              >
                                                                <div className="slide-content-services ion-text-center">
                                                                  <IonImg
                                                                    src={
                                                                      img.url
                                                                    }
                                                                    // style={{ border: img.is_feature_image === 1 ? '3px solid rgb(113 224 40)' : 'none' }}
                                                                    alt="Product"
                                                                    className="service-img"
                                                                    // onClick={() => imageView(img, style)}
                                                                  />
                                                                  <IonImg
                                                                    className="close-btn"
                                                                    onClick={() =>
                                                                      deleteImage(
                                                                        img.imageid
                                                                      )
                                                                    }
                                                                    src="assets/imgs/icons/closeIcon.svg"
                                                                  ></IonImg>
                                                                </div>
                                                              </SwiperSlide>
                                                            )
                                                          )
                                                      ) : (
                                                        <>
                                                          <SwiperSlide className="no-service-swiper">
                                                            <div className="slide-content-services ion-text-center">
                                                              <IonImg
                                                                src="assets/imgs/icons/no-service-img.svg"
                                                                alt="No Image"
                                                                className="no-service-img"
                                                              />
                                                            </div>
                                                          </SwiperSlide>
                                                          <SwiperSlide className="no-service-swiper">
                                                            <div className="slide-content-services ion-text-center">
                                                              <IonImg
                                                                src="assets/imgs/icons/no-service-img.svg"
                                                                alt="No Image"
                                                                className="no-service-img"
                                                              />
                                                            </div>
                                                          </SwiperSlide>
                                                          <SwiperSlide className="no-service-swiper">
                                                            <div className="slide-content-services ion-text-center">
                                                              <IonImg
                                                                src="assets/imgs/icons/no-service-img.svg"
                                                                alt="No Image"
                                                                className="no-service-img"
                                                              />
                                                            </div>
                                                          </SwiperSlide>
                                                        </>
                                                      )}
                                                    </Swiper>
                                                  </IonCol>
                                                  <IonCol size-md="2" size="2">
  <input
    id={`file-upload-${style.service_id}`}
    type="file"
    accept="image/*"
    multiple
    style={{ display: 'none' }}
    onChange={(event) => handleFileChange(event, user.id, style.service_id)}
  />
  <label
    htmlFor={`file-upload-${style.service_id}`}
    style={{ cursor: 'pointer' }}
  >
    <IonImg src="assets/imgs/icons/imgUpload.svg" />
  </label>
</IonCol>
                                                </IonRow>
                                              </IonGrid>
                                            </IonCol>
                                          )
                                        }
                                      )
                                    )}
                                    <div className="services-empty-div">
                                      <span> </span>
                                    </div>
                                  </React.Fragment>
                                ))
                              )}
                            </IonRow>
                          </IonGrid>
                        </div>
                      ) : (
                        <IonCol size="12">
                          <div className="fontName nocard">
                            <IonImg
                              className="no-data-img"
                              src="assets/imgs/images/no-data.svg"
                            ></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there are no Active List for you
                            </h6>
                          </div>
                        </IonCol>
                      )}
                      <div className="title-div">
                        <IonImg
                          className="service-dot-icon"
                          src="assets/imgs/icons/inActive.svg"
                        ></IonImg>
                        <h6 className="font-name-bold serices-title">
                          My Inactive Services ({inactiveCount})
                        </h6>
                      </div>
                      {services && inactiveServices.length > 0 ? (
                        <div className="the-list">
                          <IonGrid>
                            <IonRow>
                              {isProductListLoading ? (
                                <IonCol size="12">
                                  <div className="fontName nocard">
                                    <IonImg
                                      className="no-data-img"
                                      src="assets/imgs/images/no-data.svg"
                                    ></IonImg>
                                    <h6
                                      style={{
                                        color: "#515151",
                                        textAlign: "center",
                                        marginTop: "11%",
                                      }}
                                      className="fontName nocardh6"
                                    >
                                      Sorry, there are no Active List for you
                                    </h6>
                                  </div>
                                </IonCol>
                              ) : (
                                inactiveServices.map((user, i) => (
                                  <React.Fragment key={i}>
                                    <IonCol size="12">
                                      <IonLabel className="font-name-bold image-title">
                                        {user.title}
                                      </IonLabel>
                                    </IonCol>
                                    {user.subcategory.map(
                                      (subCat: any, j:any) =>
                                        subCat.styles.map(
                                          (style: any, k: any) => {
                                          return (
                                            <IonCol
                                            key={`${j}-${k}`}
                                              size-md="6"
                                              size-lg="3"
                                              size="12"
                                            >
                                              <IonGrid className="inactive-card list-p-5 overall-list">
                                                <IonRow>
                                                  <IonCol
                                                    size-md="8"
                                                    size="6"
                                                    className="second-col"
                                                  >
                                                    <div className="d-flex">
                                                      <IonLabel className="font-name-bold style-first-title service-title-pwa">
                                                        {style.title}
                                                      </IonLabel>

                                                      <IonLabel className="sub-cat-title">
                                                        {subCat.title}
                                                      </IonLabel>
                                                    </div>
                                                  </IonCol>
                                                  <IonCol size-md="4" size="6">
                                                    <div className="action-div">
                                                      <h6 className="fontName status-title">
                                                        Active
                                                      </h6>
                                                      <IonToggle
                                                        className="toggleActive  ion-float-right"
                                                        slot="end"
                                                        checked={
                                                          style.active_status ==
                                                          "1"
                                                            ? true
                                                            : false
                                                        }
                                                        onIonChange={(e) =>
                                                          handleToggleChange(
                                                            e,
                                                            style
                                                          )
                                                        }
                                                      ></IonToggle>
                                                    </div>
                                                  </IonCol>
                                                </IonRow>
                                              </IonGrid>
                                            </IonCol>
                                          )
                                        }
                                        )
                                    )}
                                  </React.Fragment>
                                ))
                              )}
                            </IonRow>
                          </IonGrid>
                        </div>
                      ) : (
                        <IonCol size="12">
                          <div className="fontName nocard">
                            <IonImg
                              className="no-data-img"
                              src="assets/imgs/images/no-data.svg"
                            ></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there are no Active List for you
                            </h6>
                          </div>
                        </IonCol>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
         
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success"
            />
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal
              isOpen={showSuspendedModal}
              id="example-modal-inactive"
              backdropDismiss={false}
            >
              <IonContent className="model-bg">
                <IonGrid className="cnfmModelGrid">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg
                        src="assets/imgs/icons/warning.svg"
                        alt="Product"
                        className="tick-img"
                      ></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName b-cnfmed-title">
                        {supendedTitle}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className="fontName thanks-cnfmed-title">
                        {supendedMsg}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className="fontName booking-details-btn"
                        onClick={() => {setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");}}
                        expand="block"
                      >
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>

            <IonModal
            ref={modal}
            isOpen={showImgViewModal}
            id="example-modal-image-view"
            backdropDismiss={false}
          >
            <IonContent className="model-bg">
              <IonGrid className="cnfmModelGrid">
                <IonRow>
                  {imageData && (
                    <>
                      <IonCol size="12" className="ion-text-center">
                        <IonImg
                          src={imageData?.url || ''}
                          alt="Product"
                          className="popup-service-img"
                        />
                      </IonCol>
                      {imageData?.is_feature_image !== 1 &&
                      <IonCol style={{ 
                        bottom: "15%",
                        left: "0px",
                        position: "fixed" 
                        }} size="12">
                      <div className="checkbx-div">
                        <IonCheckbox
                          checked={!!imageData?.is_feature_image}
                          onIonChange={() => { markAsFeaturedImage(imageData.imageid )}}
                          className="fontName"
                          mode="ios"
                        />
                        <IonLabel className="checkbx-label">
                          Make it as featured Image
                        </IonLabel>
                      </div>
                    </IonCol>
                      }
                    </>
                  )}
                  <IonCol style={{ 
                        bottom: "3%",
                        left: "0px",
                        position: "fixed" 
                        }} size="12">
                    <IonButton
                      style={{ width: "95%", margin: "auto" }}
                      className="fontName booking-details-btn"
                      onClick={() => setShowImgViewModal(false)}
                      expand="block"
                    >
                      Close
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          </IonContent>
        </>
      )}
      <WatiChat />
    </IonPage>
  );
};

export default Wallet;
