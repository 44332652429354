import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useHistory, useLocation } from "react-router-dom";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { isPlatform } from '@ionic/react';


import './Request.css'
import ProfileAddressAutocomplete from "../../components/ProfileAddress";
import WatiChat from "../../components/WatiChat";
import axios from "axios";

const Viewprofile: React.FC = () => {
  const history = useHistory();
  const inputRef = useRef(null);
  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);
  const [displayPicture, setDisplayPicture] = useState('');

  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [company_name, setCompanyName] = useState(localStorage.getItem("name") || '');
  const latestInputFirstNameValue = useRef('');

  const [inputTradingName, setInputTradingNamePattern] = useState(localStorage.getItem("tradingName") || '');
  const latestInputTradingNamePatternValue = useRef('');

  const [inputPhoneNo, setInputPhoneNoPattern] = useState(localStorage.getItem("mobile") || '');
  const [inputEmail, setInputEmailPattern] = useState(localStorage.getItem("email") || '');
  const latestInputPhoneNoPatternValue = useRef('');
  const latestInputEmailPatternValue = useRef('');

  const [roles, setRoles] = useState<Array<any>>([]);
  const [selectedRole, setSelectedRole] = useState(0);

  const [businessDropdown, setBusinessDropdown] = useState<Array<any>>([]);
  const [postcodeList, setPostcodeList] = useState<Array<any>>([]);
  const [postcodeError, setPostcodeError] = useState('');
  const [serviceAssigned, setServiceAssigned] = useState<Array<any>>([]);
  const [selectedBD, setSelectedBD] = useState(0);
  const [PClong, setPClong] = useState('');
  const [PClat, setPClat] = useState('');
  const [inputLBname, setInputLBname] = useState('');
  const latestInputLBnameValue = useRef('');

  const [inputLBtradeName, setInputLBtradeName] = useState('');
  const latestInputLBtradenameValue = useRef('');

  const [inputCompanyNo, setInputCompanyNo] = useState('');
  const latestInputCompanyNoValue = useRef('');

  const [inputBemail, setInputBemail] = useState('');
  const latestInputBemailValue = useRef('');

  const [inputBwebsite, setInputBwebsite] = useState('');
  const latestInputBwebsiteValue = useRef('');

  const [inputBnumber, setInputBnumber] = useState('');
  const latestInputBnumberValue = useRef('');

  const [inputAddressOne, setInputAddressOne] = useState('');
  const latestInputAddressOneValue = useRef('');

  const [blurDropDown, setBlurDropDown] = useState(false);


  const [phoneError, setPhoneError] = useState('');

  const [experienceList, setExperienceList] = useState<Array<any>>([]);

  const [formState, setFormState] = useState({
    firstName: '',
    firstNameError: '',
    tradingName: '',
    tradingNameError: '',
    phoneNo: '',
    phoneNoError: '',
    email: '',
    emailError: '',
    LBname: '',
    LBnameError: '',
    LBtradeName: '',
    LBtradeNameError: '',
    companyNo: '',
    companyNoError: '',
    bEmail: '',
    bEmailError: '',
    bWebsite: '',
    bWebsiteError: '',
    bNumber: '',
    bNumberError: '',
    addressOne: '',
    addressOneError: '',
    addressTwo: '',
    addressTwoError: '',
    countryError: '',
    country: '',
    townError: '',
    town: ''
  });

  const experienceDropdown = [
    { "id": '1', "years": '1 Year' },
    { "id": '2', "years": '2 Years' },
    { "id": '3', "years": '3 Years' },
    { "id": '4', "years": '4 Years' },
    { "id": '5', "years": '5 Years' },
    { "id": '6', "years": '6 Years' },
    { "id": '7', "years": '7 Years' },
    { "id": '8', "years": '8 Years' },
    { "id": '9', "years": '9 Years' },
    { "id": '10', "years": '10 Years' },
    { "id": '11', "years": '11 Years' },
    { "id": '12', "years": '12 Years' },
    { "id": '13', "years": '13 Years' },
    { "id": '14', "years": '14 Years' },
    { "id": '15', "years": '15+ Years' }
  ]

  const [formData, setFormData] = useState({
    "company_name": "",
    "aboutme": "",
    "firstname": "",
    "lastname": "",
    "profilelocation": "",
    "address": "",
    "contact_number": "",
    "facebook": "",
    "instagram": "",
    "glamurl": "",
    "insured": "2",
    "experience": "",
    "policy_no": "",
    "insurer_name": "",
    "expiry_date": "",
    "lat": "",
    "lng": ""
  });

  const [showActionSheet, setShowActionSheet] = useState(false);
  const capturedImagesRef = useRef<File[]>([]);


  const goBack = () => {
    history.goBack();
    focusOut();
    setFormData({
      ...formData,
      "company_name": "",
      "aboutme": "",
      "firstname": "",
      "lastname": "",
      "profilelocation": "",
      "address": "",
      "contact_number": "",
      "facebook": "",
      "instagram": "",
      "glamurl": "",
      "insured": "2",
      "experience": "",
      "policy_no": "",
      "insurer_name": "",
      "expiry_date": "",
      "lat": "",
      "lng": ""
    })
  };

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);


  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      setNetworkStatus(status.connected);
    });

    getExperience();

  }, []);

  const getExperience = () => {

    // setLoading(true);
    // setBackdropVisible(true);

    apiConfig.get(`/experience`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
       setExperienceList(res.data.data);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const handleUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];

        setLoading(true);
        setBackdropVisible(true);

        try {
          const blob = await file.arrayBuffer().then(data => new Blob([data]));
          const img = await createImageBitmap(blob);

          const canvas = document.createElement('canvas');
          // Resize image here:
          canvas.width = img.width > 1000 ? 1000 : img.width;
          canvas.height = img.width > 1000 ? Math.floor((img.height * 1000) / img.width) : img.height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            console.error('Unable to get 2D context');
            return null;
          }
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const optimizedBlob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7)
          );

          if (!optimizedBlob) {
            console.error('Unable to create blob from canvas');
            return null;
          }

          const reader = new FileReader();
          const dataUrlPromise = new Promise<string>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
          });
          reader.readAsDataURL(optimizedBlob);

          const dataUrl = await dataUrlPromise;

          handleSubmit(dataUrl);

        } catch (error) {
          console.error('Error uploading image:', error);
        } finally {
          setLoading(false);
          setBackdropVisible(false);
        }
      }
    };

    input.click();
    setShowActionSheet(false);
  };

  const handleCapture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
    });
    setLoading(true);
    setBackdropVisible(true);
    const imageUrl = Capacitor.convertFileSrc(image.webPath!);
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Convert blob to ImageBitmap
    const img = await createImageBitmap(blob);

    // Create a canvas and resize the image
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800 * (img.height / img.width);

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Unable to get 2D context');
      return;
    }
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Convert the canvas image back to a blob
    const resizedBlob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7)
    );

    if (!resizedBlob) {
      console.error('Unable to create blob from canvas');
      return;
    }

    // Convert the blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(resizedBlob);
    reader.onloadend = () => {
      const base64data = reader.result;

      // Call handleSubmit with the base64 string
      handleSubmit(base64data);

      setLoading(false);
      setBackdropVisible(false);
    };

    reader.onerror = (error) => {
      console.error('Error converting blob to base64:', error);
      setLoading(false);
      setBackdropVisible(false);
    };
  };
  const handleCancel = () => {
    setShowActionSheet(false);
  };

  const handleActionSheet = () => {
    setShowActionSheet(true);
  };

  const handleSubmit = (provider_image: any) => {
    // event.preventDefault();
    setLoading(true);
    setBackdropVisible(true);
    // const formData = new FormData();

    // formData.append('provider_image', JSON.stringify(provider_image));

    apiConfig.patch(`/profile/${localStorage.getItem('userId')}`, {
      'provider_image': provider_image
    }, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.data.status == true) {
        localStorage.setItem("userImage", response.data.data.image);
        setDisplayPicture(response.data.data.image);
        // formData.delete('token');
        capturedImagesRef.current = [];
        setLoading(false);
        setBackdropVisible(false);
        setShowToastSuccess(true);
        setSuccessMessage(response.data.message);
      } else {
        setLoading(false);
        setBackdropVisible(false);
        setErrorMessage(response.data.message);
        setShowErrorToast(true);
      }

      setTimeout(() => {
        if (loading == true) {
          setLoading(false);
          setBackdropVisible(false);
          // setErrorMessage("Something went wrong on API please try again");
          // setShowToast(true);
        }
      }, 10000);
      setTimeout(() => {
        if (loading == true) {
          setLoading(false);
          setBackdropVisible(false);
          // setErrorMessage("Something went wrong on API please try again");
          // setShowToast(true);
        }
      }, 10000);
    })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });


  };

  useIonViewWillEnter(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    } else {
      getUserDetails();
      setDisplayPicture(`${localStorage.getItem("userImage")}`);
  
      if (localStorage.getItem("darkMode") == "darkMode") {
        setIsDarkModeActive(true);
      } else {
        setIsDarkModeActive(false);
      }
    }
   
  }, [])

  const formatAPIDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Converts to YYYY-MM-DD format
  };

  const getUserDetails = () => {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig.get(`/profile/${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.data.status == true) {
        setLoading(false);
        setBackdropVisible(false);

        setDisplayPicture(response.data.data.image);
        localStorage.setItem("userImage", response.data.data.image);

        setFormData({
          ...formData,
          "aboutme": response.data.data.aboutme,
          "address": response.data.data.address,
          "company_name": response.data.data.company_name,
          "firstname": response.data.data.firstname,
          "lastname": response.data.data.lastname,
          "profilelocation": response.data.data.profilelocation,
          "lat": response.data.data.lat,
          "lng": response.data.data.lng,
          "contact_number": response.data.data.contact_number,
          "facebook": response.data.data.facebook,
          "instagram": response.data.data.instagram,
          "glamurl": response.data.data.glamurl,
          "insured": response.data.data.insured,
          "experience": JSON.parse(response.data.data.experience),
          "policy_no": response.data.data.policy_no,
          "insurer_name": response.data.data.insurer_name,
          "expiry_date": response.data.data.expiry_date ? formatAPIDate(response.data.data.expiry_date) : '',
        })

      } else {
        setLoading(false);

        setBackdropVisible(false);
      }
      setTimeout(() => {
        if (loading == true) {
          setLoading(false);
          setBackdropVisible(false);
          // setErrorMessage("Something went wrong on API please try again");
          // setShowToast(true);
        }
      }, 10000);
    })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
  }

  const handleFirstNameInputChanges = (event: any) => {
    const firstName = event.target.value;
    // setInputFirstName(event.target.value);
    latestInputFirstNameValue.current = firstName;
    // const firstNamePattern = /^[a-zA-Z]+ [a-zA-Z]+$/;
    // if (firstName && !firstNamePattern.test(firstName)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     firstNameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     firstNameError: ''
    //   }));
    // }
  };

  const handlePlaceSelect = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
  
    const address = place.formatted_address || '';
    
    setFormData({
      ...formData,
      "address": address,
      "lat": latitude,
      "lng": longitude
    });
  };

  const handleYears = (value: any) => {

    setFormData({
      ...formData,
      'experience': value,
    });

  }

  const handleInputvalue = (field: string, value: any) => {


    if (field === 'contact_number') {
      if (value.length === 11 && value.startsWith('0')) {
        setPhoneError('');
      } else {
        setPhoneError(
          value.length !== 11
            ? 'Phone number should be 11 digits long'
            : 'Phone number should start with 0'
        );
      }
    }

    setFormData({
      ...formData,
      [field]: value,
    });
  }

  const handleCheckboxChange = (field: string, isChecked: boolean) => {

    if (isChecked) {
      setFormData({
        ...formData,
        'insured': field === 'yes' ? "1" : "2",
      });
    }
  };

  const handleTradingNameInputChanges = (event: any) => {
    const tradingName = event.target.value;
    setInputTradingNamePattern(event.target.value);
    latestInputTradingNamePatternValue.current = tradingName;
    // const tradingNamePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (tradingName && !tradingNamePattern.test(tradingName)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     tradingNameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     tradingNameError: ''
    //   }));
    // }
  };
  const handlePhoneNoInputChanges = (event: any) => {
    const phoneNo = event.target.value;
    setInputPhoneNoPattern(event.target.value);
    latestInputPhoneNoPatternValue.current = phoneNo;
    const phoneNoPattern = /^[0-9]+$/;
    if (phoneNo && !phoneNoPattern.test(phoneNo)) {
      setFormState(prevState => ({
        ...prevState,
        phoneNoError: 'Invalid Phone Number'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        phoneNoError: ''
      }));
    }
  };

  useEffect(() => {
    setUnauthorizedHandler(() => {
      setSupendedMsg("Your account is inactive, please contact support.");
      setSupendedTitle("Inactive");
      setShowSuspendedModal(true);
    });

    return () => {
      setUnauthorizedHandler(() => {});
    };
  }, []);

  const submitUserDetails = () => {

    if (phoneError == '') {
      if (formData.company_name !== '' && formData.firstname !== '' && formData.lastname !== '' && formData.address !== '' && formData.contact_number !== '' && formData.insured !== '' && formData.experience !== '' && formData.experience !== '' && formData.aboutme !== '') {

        setLoading(true);
        setBackdropVisible(true);

        apiConfig.patch(`/profile/${localStorage.getItem('userId')}`, formData, {
          headers: {
            'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (response.data.status == true) {
            localStorage.setItem("userName", response.data.data.firstname);
            localStorage.setItem("lastName", response.data.data.lastname);
            localStorage.setItem("userImage", response.data.data.image);
            localStorage.setItem("userMail", response.data.data.email);
            history.push("tabs/profile");
            setSuccessMessage(response.data.message);
            setShowToastSuccess(true);
            setLoading(false);
            setBackdropVisible(false);
          } else {
            setErrorMessage(response.data.message);
            setShowErrorToast(true);
            setLoading(false);
            setBackdropVisible(false);
          }
          setTimeout(() => {
            if (loading == true) {
              setLoading(false);
              setBackdropVisible(false);
              // setErrorMessage("Something went wrong on API please try again");
              // setShowToast(true);
            }
          }, 10000);
        })
          .catch(error => {
            if (error.code == "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
          });
      } else {
        setErrorMessage('Please enter all the mandatory fields');
        setShowErrorToast(true);
      }

    } else {
      setErrorMessage('Please enter a valid phone number');
      setShowErrorToast(true);
    }


  }

  const handleLBnameInputChanges = (event: any) => {
    const LBname = event.target.value;
    setInputLBname(event.target.value);
    latestInputLBnameValue.current = LBname;
    // const LBnamePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (LBname && !LBnamePattern.test(LBname)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBnameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBnameError: ''
    //   }));
    // }
  }

  const handleLBtradeNameInputChanges = (event: any) => {
    const LBtradeName = event.target.value;
    setInputLBtradeName(event.target.value);
    latestInputLBtradenameValue.current = LBtradeName;
    // const LBtradeNamePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (LBtradeName && !LBtradeNamePattern.test(LBtradeName)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBtradeNameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBtradeNameError: ''
    //   }));
    // }
  }

  const handleCompanyNoInputChanges = (event: any) => {
    const CompanyNo = event.target.value;
    setInputCompanyNo(event.target.value);
    latestInputCompanyNoValue.current = CompanyNo;
    const companyNoPattern = /^[0-9]+$/;
    if (CompanyNo && !companyNoPattern.test(CompanyNo)) {
      setFormState(prevState => ({
        ...prevState,
        companyNoError: 'Invalid Company Number'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        companyNoError: ''
      }));
    }
  }

  const handleBemailInputChanges = (event: any) => {
    const bEmail = event.target.value;
    setInputBemail(event.target.value);
    latestInputBemailValue.current = bEmail;
    const bEmailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (bEmail && !bEmailPattern.test(bEmail)) {
      setFormState(prevState => ({
        ...prevState,
        bEmailError: 'Invalid email address'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        bEmailError: ''
      }));
    }
  }

  const getActionSheetButtons = () => {
    const buttons = [
      {
        text: 'Upload',
        handler: () => handleUpload(),
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ];

    // Only add the 'Capture' button if not on the web platform
    if (!isPlatform('hybrid') && !isPlatform('desktop')) {
      buttons.unshift({
        text: 'Capture',
        handler: () => handleCapture(),
      });
    }

    return buttons;
  };

  const handleBwebsiteInputChanges = (event: any) => {
    const bWebsite = event.target.value;
    setInputBwebsite(event.target.value);
    latestInputBwebsiteValue.current = bWebsite;
    // const bWebsitePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (bWebsite && !bWebsitePattern.test(bWebsite)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     bWebsiteError: 'Invalid Website'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     bWebsiteError: ''
    //   }));
    // }
  }
  const handleBnumberInputChanges = (event: any) => {
    const bNumber = event.target.value;
    setInputBnumber(event.target.value);
    latestInputBnumberValue.current = bNumber;
    const bNumberPattern = /^[0-9]+$/;
    if (bNumber && !bNumberPattern.test(bNumber)) {
      setFormState(prevState => ({
        ...prevState,
        bNumberError: 'Invalid Bussiness Number'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        bNumberError: ''
      }));
    }
  }
  const getPostCode = (postcode: any) => {
    // setLoading(true);
    // setBackdropVisible(true);
    if (postcode) {
      setPostcodeError('');
      axios.get(`https://api.postcodes.io/postcodes?q=${postcode}&format=json`)
        .then(response => {
          setPostcodeList(response.data.result)
        })
        .catch(error => {
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          // setLoading(false);
          // setBackdropVisible(false);
        });
    } else {
      setPostcodeList([]);
      setPostcodeError("Please Enter a valid postcode")
    }
  }
  const focusOut = () => {
    setBlurDropDown(false);
  }
  const handleAddressOneInputChanges = (event: any) => {
    latestInputAddressOneValue.current = '';
    setPClong('');
    setPClat('');
    setInputAddressOne(event.target.value);
    getPostCode(event.target.value);
    setBlurDropDown(true);

  }

  const handleOptionClick = (option: string) => {
    setBlurDropDown(true);
    setInputAddressOne(option);
    latestInputAddressOneValue.current = option
  };



  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == true &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == true &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="background-holder"></div>
            <div onClick={focusOut} className="profile-top-card dark-profile-top-card animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonLabel className="dark-title">User / Login Details</IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        // value={inputFirstName}
                        onChange={e => handleFirstNameInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">First Name</label>
                    </div>
                    {formState.firstNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.firstNameError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputTradingName}
                        onChange={e => handleTradingNameInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Surname</label>
                    </div>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputPhoneNo}
                        onChange={e => handlePhoneNoInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                        minLength={10}
                        maxLength={10}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Phone Number</label>
                    </div>
                    {formState.phoneNoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.phoneNoError}</span>}
                  </IonCol>
                  {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <IonSelect interface="popover" className='fontName dark-form-control-model' placeholder="Select Role" value={selectedRole} onIonChange={(e) => setSelectedRole(e.detail.value)}>
                        {roles.map((role) => (
                          <IonSelectOption key={role.id} value={role.id}>{role.value}</IonSelectOption>
                        ))}
                      </IonSelect>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Your Role</label>
                    </div>
                  </IonCol> */}
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputEmail}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Email</label>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" col-12 onClick={submitUserDetails}>
                    <IonButton expand="block" className="booking-details-btn">
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div onClick={focusOut} className="dark-v-profile-td-card animate__animated animate__fadeInUp animate__slow ion-text-center">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonLabel className="dark-login-edit-title">Legal Information</IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputLBname}
                        onChange={e => handleLBnameInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Legal Business Name</label>
                    </div>
                    {formState.LBnameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.LBnameError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputLBtradeName}
                        onChange={e => handleLBtradeNameInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Trading Name</label>
                    </div>
                    {formState.LBtradeNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.LBtradeNameError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <IonSelect interface="popover" className='fontName dark-form-control-model' placeholder="Select Bussiness Type--" value={selectedBD} onIonChange={(e) => setSelectedBD(e.detail.value)}>
                        {businessDropdown.map((businessDropdown) => (
                          <IonSelectOption key={businessDropdown.id} value={businessDropdown.id}>{businessDropdown.value}</IonSelectOption>
                        ))}
                      </IonSelect>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Business Type</label>
                    </div>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputCompanyNo}
                        onChange={e => handleCompanyNoInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Company Number</label>
                    </div>
                    {formState.companyNoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.companyNoError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputBemail}
                        onChange={e => handleBemailInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Business Email Adress</label>
                    </div>
                    {formState.bEmailError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bEmailError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputBwebsite}
                        onChange={e => handleBwebsiteInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Website</label>
                    </div>
                    {formState.bWebsiteError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bWebsiteError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputBnumber}
                        onChange={e => handleBnumberInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Phone Number</label>
                    </div>
                    {formState.bNumberError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bNumberError}</span>}
                  </IonCol>
                  <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputAddressOne}
                        onChange={e => handleAddressOneInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      // onDrop={focusOut}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Postcode</label>
                    </div>
                    {/* <div className="dropdown"> */}
                    {postcodeList !== null &&
                      <div className={postcodeList.length == 0 || latestInputAddressOneValue.current !== '' || blurDropDown == false ? "dropdown-content" : "dark-dropdown-content-avail"}>
                        {postcodeList.map((post: any, index: any) => (
                          <IonItem className="fontName dark-item" key={index} onClick={() => { handleOptionClick(post.postcode); setPClong(post.longitude); setPClat(post.latitude); }}>
                            {post.postcode}
                          </IonItem>
                        ))}
                      </div>
                    }
                    {postcodeList == null &&
                      <div className={blurDropDown == false ? "dropdown-content" : "dark-dropdown-content-avail-null"}>
                        <h6 className="fontName" style={{ marginTop: "20%", color: "#CCCCCC" }}>No results found</h6>
                      </div>
                    }
                    {/* </div> */}
                    <span className='fontName emailErrorMsg'>{postcodeError}</span>
                  </IonCol>
                  {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputAddressTwo}
                        onChange={e => handleAddressTwoInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Adress Line 2</label>
                    </div>
                    {formState.addressTwoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.addressTwoError}</span>}
                  </IonCol> */}
                  {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputTown}
                        onChange={e => handleTownInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Town / City</label>
                    </div>
                    {formState.townError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.townError}</span>}
                  </IonCol> */}
                  {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputCountry}
                        onChange={e => handleCountryInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Country</label>
                    </div>
                    {formState.countryError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.countryError}</span>}
                  </IonCol> */}
                </IonRow>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonButton expand="block" className="booking-details-btn">
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div onClick={focusOut} className="dark-v-profile-last-card animate__animated animate__fadeInUp animate__slow ion-text-center">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonLabel className="dark-login-edit-title">Assigned Categories</IonLabel>
                  </IonCol>
                  {serviceAssigned.slice(0, 9).map((service) => (
                    <IonCol col-4 size="4">
                      <h6 className="service-cat-chip">{service.title}</h6>
                    </IonCol>
                  ))}
                  {serviceAssigned.length > 9 &&
                    <IonCol col-12 size="12">
                      <IonButton
                        routerLink="/requestreview"
                        routerDirection="none"
                        className="fontName dark-view-more"
                      >
                        View more
                      </IonButton>
                      {/* <h6 className="fontName view-more"></h6> */}
                    </IonCol>
                  }
                </IonRow>
              </IonGrid>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-inactive" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => { setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no"); }}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />

          </IonContent>
        </>
      }
      {networkStatus == false && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ textAlign: "center" }}>
                Edit Profile
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="center-grid">
              <div onClick={focusOut} className="upload-top-card animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow className="upload-row">
                    <IonCol size="4">
                      <IonAvatar className="upload-img-avatar">
                        <IonImg src={displayPicture ? displayPicture : "assets/imgs/icons/light-profile.svg"} ></IonImg>
                      </IonAvatar>
                    </IonCol>
                    <IonCol size="8">
                      <IonButton onClick={handleActionSheet} expand="block" className="secondary-button location-btn">
                        <IonIcon
                          className="fav btn-icon"
                          src="assets/imgs/icons/upload-img.svg"
                        /><span className="order-span">Change Photo</span>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <div style={{ marginTop: "3vh", marginBottom: "5vh" }} onClick={focusOut} className="upload-top-card animate__animated animate__fadeInUp animate__slow ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.company_name}
                          onChange={e => handleInputvalue('company_name', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Business Name<span style={{ color: '#FF0000' }}>*</span> </label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <textarea
                          className='form-control-model fontName'
                          value={formData.aboutme}
                          onChange={e => handleInputvalue('aboutme', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></textarea>
                        <label className="label-outline-model" htmlFor="textAreaExample">About Description<span style={{ color: '#FF0000' }}>*</span></label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.firstname}
                          onChange={e => handleInputvalue('firstname', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">First Name<span style={{ color: '#FF0000' }}>*</span></label>
                      </div>
                      {formState.firstNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.firstNameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.lastname}
                          onChange={e => handleInputvalue('lastname', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Surname<span style={{ color: '#FF0000' }}>*</span></label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          minLength={11}
                          maxLength={11}
                          value={formData.contact_number}
                          onChange={e => handleInputvalue('contact_number', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Phone Number<span style={{ color: '#FF0000' }}>*</span></label>
                      </div>
                      {phoneError && <p className='fontName emailErrorMsg'>{phoneError}</p>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <ProfileAddressAutocomplete onPlaceSelect={handlePlaceSelect} address={formData.address} />
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.profilelocation}
                          onChange={e => handleInputvalue('profilelocation', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Location Display Name</label>
                        <p className="note-text">*Note: Please enter your city name, country name*</p>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.facebook}
                          onChange={e => handleInputvalue('facebook', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Facebook URL</label>
                      </div>
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.instagram}
                          onChange={e => handleInputvalue('instagram', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Instagram</label>
                      </div>
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={formData.glamurl}
                          onChange={e => handleInputvalue('glamurl', e.target.value)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Website</label>
                      </div>
                      {formState.bWebsiteError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bWebsiteError}</span>}
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonSelect interface="popover" className='fontName form-control-model' placeholder="Select Experience" value={formData.experience} onIonChange={(e) => handleYears(e.detail.value)}>
                          
                        {experienceList.length !== 0 &&
                          experienceList.map((experience: any) => (
                            <IonSelectOption key={experience.id} value={experience.id}>{experience.experience}</IonSelectOption>
                          ))}

                          {experienceList.length == 0 &&
                          experienceDropdown.map((experience: any) => (
                            <IonSelectOption key={experience.id} value={experience.id}>{experience.years}</IonSelectOption>
                          ))}



                        </IonSelect>
                        <label className="label-outline-model" htmlFor="textAreaExample">Years of Experience<span style={{ color: '#FF0000' }}>*</span></label>
                      </div>
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonLabel className="checkbox-title">Are You/Your Business Insured?<span style={{ color: '#FF0000' }}>*</span></IonLabel>
                        <br />
                        <div className="profile-checkbx-div">
                          <IonCheckbox
                            checked={formData.insured == "1" ? true : false}
                            className='fontName'
                            mode="ios"
                            onIonChange={(e) => handleCheckboxChange('yes', e.detail.checked)} // Adjusted this line
                          ></IonCheckbox>
                          <IonLabel className="checkbx-label">Yes</IonLabel>

                          <IonCheckbox
                            style={{ marginLeft: "5%" }}
                            checked={formData.insured == "2" ? true : false}
                            className='fontName'
                            mode="ios"
                            onIonChange={(e) => handleCheckboxChange('no', e.detail.checked)} // And this line
                          ></IonCheckbox>
                          <IonLabel className="checkbx-label">No</IonLabel>
                        </div>
                      </div>
                    </IonCol>
                    {formData.insured == "1" && (
                      <>
                        <IonCol size="12" col-12>
                          <div className="model-container">
                            <input
                              className='form-control-model fontName'
                              value={formData.policy_no}
                              onChange={e => handleInputvalue('policy_no', e.target.value)}
                              onFocus={(e) => e.preventDefault()}
                              minLength={10}
                              maxLength={10}
                            ></input>
                            <label className="label-outline-model" htmlFor="textAreaExample">Policy Number</label>
                          </div>
                          {formState.bNumberError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bNumberError}</span>}
                        </IonCol>
                        <IonCol size="12" col-12>
                          <div className="model-container">
                            <input
                              className='form-control-model fontName'
                              value={formData.insurer_name}
                              onChange={e => handleInputvalue('insurer_name', e.target.value)}
                              onFocus={(e) => e.preventDefault()}
                              minLength={10}
                              maxLength={10}
                            ></input>
                            <label className="label-outline-model" htmlFor="textAreaExample">Name of insurer</label>
                          </div>
                        </IonCol>
                        <IonCol size="12" col-12>
                          <div className="model-container">
                            <input
                              className='form-control-model form-control-model-date fontName'
                              type="date"
                              value={formData.expiry_date ? formData.expiry_date : ''}
                              id="expirydate"
                              onChange={e => handleInputvalue('expiry_date', e.target.value)}
                              name="expirydate"
                            />
                             <IonIcon src="../../assets/imgs/icons/avail.svg" className='eye-icon date-icon input-pound-label'>pounds</IonIcon>
                            <label className="label-outline-model" htmlFor="expirydate">Expiry Date</label>
                          </div>

                        </IonCol>
                      </>
                    )}

                  </IonRow>
                  <IonRow>
                    <IonCol size="12" col-12 >
                      <IonButton expand="block" onClick={submitUserDetails} className="save-btn-profile">
                        Save Settings
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
           <IonActionSheet
              isOpen={showActionSheet}
              onDidDismiss={() => setShowActionSheet(false)}
              buttons={getActionSheetButtons()}
            ></IonActionSheet>
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no"); }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
          </IonContent>
        </>
      }
      <WatiChat />
    </IonPage >
  );
};

export default Viewprofile;
