import React, { useEffect, useState, useRef } from 'react';

interface AddressAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  address: string; // Added this line to include address in the props
}

const loadGoogleMapsScript = (callback: () => void) => {
  const scriptId = 'googleMapsScript';
  if (!document.getElementById(scriptId)) {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBxx9vKxSe7qftcutFSbgvxC2LGZJx_s8E&libraries=places';
    script.id = scriptId;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};

const ProfileAddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onPlaceSelect, address }) => {
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = event.target.value;
  
    // Mocking the LatLng instance for latitude and longitude
    const mockLatLng = new google.maps.LatLng(0, 0); // You can adjust these values as needed
  
    // Mocking the viewport. You can set the bounds to your desired values
    const mockViewport = new google.maps.LatLngBounds(
      new google.maps.LatLng(0, 0), // southwest corner
      new google.maps.LatLng(0, 0)  // northeast corner
    );
  
    onPlaceSelect({
      formatted_address: newAddress,
      geometry: {
        location: mockLatLng,
        viewport: mockViewport // Mocked viewport
      },
      name: ''
    });
  };


  useEffect(() => {
    loadGoogleMapsScript(() => {
      setIsScriptLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (isScriptLoaded && window.google && autocompleteRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        onPlaceSelect(place);
      });
    }
  }, [isScriptLoaded, onPlaceSelect]);

  useEffect(() => {
    // This effect updates the input value when the address prop changes
    if (autocompleteRef.current) {
      autocompleteRef.current.value = address;
    }
  }, [address]);

  return (
    <div className="model-container">
      <input
        ref={autocompleteRef}
        type="text"
        placeholder="Enter your address"
        className="form-control-model fontName"
        value={address}  // Controlled input
        onChange={handleInputChange}  // Update address when user types
      />
      <label className="label-outline-model" htmlFor="textAreaExample">Address<span style={{ color: '#FF0000' }}>*</span></label>
    </div>
  );
};

export default ProfileAddressAutocomplete;
