import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Notifications.css";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Network } from '@capacitor/network';
import WatiChat from "../../components/WatiChat";

const Notifications: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");


  const history = useHistory();
  const [segment, setSegment] = useState('booking');
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [notificationList, setNotificationList] = useState<Array<any>>([]);
  const [notificationGeneral, setNotificationGeneral] = useState<Array<any>>([]);
  const [notificationBooking, setNotificationBooking] = useState<Array<any>>([]);

  const [recent, setRecent] = useState<Array<any>>([]);
  const [today, setToday] = useState<Array<any>>([]);
  const [yesterday, setYesterday] = useState<Array<any>>([]);
  const [thisWeek, setThisWeek] = useState<Array<any>>([]);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [NotificationsAvail, setNotificationsAvail] = useState(false);
  const [NotificationsGeneralAvail, setNotificationsGeneralAvail] = useState(false);
  const [NotificationsAllAvail, setNotificationsAllAvail] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const ONE_HOUR_IN_MS = 60 * 60 * 1000;
  const ONE_DAY_IN_MS = 24 * ONE_HOUR_IN_MS;

  const currentDate = new Date();


  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      setNetworkStatus(status.connected);
    });
  }, []);

  useEffect(() => {
  
    setUnauthorizedHandler(() => {
      setSupendedMsg("Your account is inactive, please contact support.");
      setSupendedTitle("Inactive");
      setShowSuspendedModal(true);
    });

    return () => {
      setUnauthorizedHandler(() => {});
    };
  }, []);

  useIonViewWillEnter(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    } else {
      if (segment == "booking") {
        notificationListBookings();
      } else if (segment == "general") {
        notificationListGeneral();
      } else {
        notificationListAll();
      }
    }
  }, []);
  // useEffect(() => {
  //   notificationSort(currentDate);
  // }, [notificationList, currentDate]);
  const notificationListBookings = () => {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig.get(`/partnerpushnotification?userid=${localStorage.getItem('userId')}&type=B&fcm_token=${localStorage.getItem("fcmToken")}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.data.data.length !== 0) {
        setNotificationBooking(response.data.data);
        setNotificationsAvail(false);
        setLoading(false);
        notificationSort(response.data.data, currentDate);
        setBackdropVisible(false);
      } else {
        setNotificationsAvail(true);
        setNotificationBooking(response.data.data);
        setLoading(false);
        notificationSort(response.data.data, currentDate);
        setBackdropVisible(false);
      }
      setLoading(false);
      setBackdropVisible(false);

      setTimeout(() => {
        if (loading == true) {
          setLoading(false);
          setBackdropVisible(false);
          // setErrorMessage("Something went wrong on API please try again");
          // setShowToast(true);
        }
      }, 10000);
    })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }
  const notificationListGeneral = () => {
    // setNotificationGeneral([]);
    setLoading(true);
    setBackdropVisible(true);
    apiConfig.get(`/partnerpushnotification?userid=${localStorage.getItem('userId')}&type=G&fcm_token=${localStorage.getItem("fcmToken")}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.data.data.length !== 0) {
          setNotificationGeneral(response.data.data);
          setLoading(false);
          setNotificationsGeneralAvail(false);
          setBackdropVisible(false);
        } else {
          setNotificationsGeneralAvail(true);
          setNotificationGeneral(response.data.data);
          setLoading(false);
          setBackdropVisible(false);
        }
        setLoading(false);
        setBackdropVisible(false);

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }
  const notificationListAll = () => {
    // setNotificationList([]);
    setLoading(true);
    setBackdropVisible(true);
    apiConfig.get(`/partnerpushnotification?userid=${localStorage.getItem('userId')}&type=A&fcm_token=${localStorage.getItem("fcmToken")}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.data.data.length !== 0) {
        setNotificationList(response.data.data);
        setLoading(false);
        setNotificationsAllAvail(false);
        setBackdropVisible(false);
      } else {
        setNotificationList(response.data.data);
        setLoading(false);
        setNotificationsAllAvail(true);
        setBackdropVisible(false);
      }
      setLoading(false);
      setBackdropVisible(false);

      setTimeout(() => {
        if (loading == true) {
          setLoading(false);
          setBackdropVisible(false);
          // setErrorMessage("Something went wrong on API please try again");
          // setShowToast(true);
        }
      }, 10000);
    })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }
  const notificationReadStatus = (notify: any, tab: any) => {

    setLoading(true);
    setBackdropVisible(true);
    apiConfig.patch(`/partnerpushnotification/${notify.id}`,
      {
        "status": 2
      },
      {
        headers: {
          'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.data.status == true) {
          if (tab !== "general") {
            if (notify.bookings == null) {
              setErrorMessage("This is an old notification");
              setShowToast(true);
            } else {
              localStorage.setItem("bookingId", notify.bookingid);

              if (notify.bookings.spuser_id == localStorage.getItem('userId')) {
                if (notify.bookings.status == 5) {
                  setErrorMessage("You rejected this booking");
                  setShowToast(true);
                } else {
                  history.push('/viewbooking');
                }

              } else {

                setErrorMessage("This Booking is not your's");
                setShowToast(true);

              }
            }
          }
          if (segment == "booking") {
            notificationListBookings();
          } else if (segment == "general") {
            notificationListGeneral();
          } else {
            notificationListAll();
          }
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setLoading(false);
          setBackdropVisible(false);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }

  const redirectND = (id: any) => {
    history.push({
      pathname: '/notificationDetail',
      state: { data: id }
    });
  }

  const notificationSort = (notificationData: any[], currentDate: any) => {
    const notificationListWithDates = notificationData.map(user => ({
      ...user,
      formatted_date: new Date(user.formatted_date)
    }));

    const recentNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      return currentDate - notificationDate <= ONE_HOUR_IN_MS;
    });

    const todayNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      const isSameDay = notificationDate.toDateString() === currentDate.toDateString();
      return isSameDay && currentDate - notificationDate > ONE_HOUR_IN_MS;
    });

    const yesterdayNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      const isYesterday = notificationDate.toDateString() === new Date(currentDate - ONE_DAY_IN_MS).toDateString();
      return isYesterday;
    });
    const thisWeekNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      const timeDiff = currentDate - notificationDate;
      const daysSinceNotification = timeDiff / ONE_DAY_IN_MS;
      return daysSinceNotification <= 7 && daysSinceNotification > 1;
    });
    setRecent(recentNotifications);
    setToday(todayNotifications);
    setYesterday(yesterdayNotifications);
    setThisWeek(thisWeekNotifications);
  }

  const handleInputChange = (event: any) => {
    // setIsBookingsLoading(true);
    setSegment(event.detail.value);
    if (event.detail.value == "booking") {
      notificationListBookings();
    } else if (event.detail.value == "general") {
      notificationListGeneral();
    } else {
      notificationListAll();
    }
  };
  const goBack = () => {
    history.goBack();
  };

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">Notifications</IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/login"
                routerDirection="none"
              >
                {/* <IonIcon
  className="fav header-icons"
  src="assets/imgs/icons/backArrow.svg"
  slot="end"
/> */}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader><IonContent fullscreen className="notifications dark-mode-ion-content ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent></>
      }
      {networkStatus == true &&
        darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center dark-ion-title">Notifications</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/login"
                  routerDirection="none"
                >
                  {/* <IonIcon
                className="fav header-icons"
                src="assets/imgs/icons/backArrow.svg"
                slot="end"
              /> */}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="notifications dark-mode-ion-content ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div className="section animate__animated animate__fadeInUp">

              {/* <h2>Recent Notifications</h2>
          {recent.map((user, i) => (
            <IonGrid key={i} className="listGrid">
              <IonRow>
                <IonCol size='2' col-2>
                  <IonAvatar className="notification-img">
                    {user.image == null && (
                      <IonImg src="assets/imgs/images/no-image.jpg"></IonImg>
                    )}
                    {user.image != null && (
                      <IonImg src={user.image}></IonImg>
                    )}
                  </IonAvatar>
                </IonCol>
                <IonCol size='10' col-10>
                  <IonLabel className="notification-msg">{user.notification_message}</IonLabel>
                </IonCol>
                <IonCol size='12' col-12>
                  <IonLabel className="notification-date-time">{user.formatted_date.toString()}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}

          <h2>Today's Notifications</h2>
          {today.map((user, i) => (
            <IonGrid key={i} className="listGrid">
              <IonRow>
                <IonCol size='2' col-2>
                  <IonAvatar className="notification-img">
                    {user.image == null && (
                      <IonImg src="assets/imgs/images/no-image.jpg"></IonImg>
                    )}
                    {user.image != null && (
                      <IonImg src={user.image}></IonImg>
                    )}
                  </IonAvatar>
                </IonCol>
                <IonCol size='10' col-10>
                  <IonLabel className="notification-msg">{user.notification_message}</IonLabel>
                </IonCol>
                <IonCol size='12' col-12>
                  <IonLabel className="notification-date-time">{user.formatted_date.toString()}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}

          <h2>Yesterday's Notifications</h2>
          {yesterday.map((user, i) => (
            <IonGrid key={i} className="listGrid">
              <IonRow>
                <IonCol size='2' col-2>
                  <IonAvatar className="notification-img">
                    {user.image == null && (
                      <IonImg src="assets/imgs/images/no-image.jpg"></IonImg>
                    )}
                    {user.image != null && (
                      <IonImg src={user.image}></IonImg>
                    )}
                  </IonAvatar>
                </IonCol>
                <IonCol size='10' col-10>
                  <IonLabel className="notification-msg">{user.notification_message}</IonLabel>
                </IonCol>
                <IonCol size='12' col-12>
                  <IonLabel className="notification-date-time">{user.formatted_date.toString()}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))} */}

              {/* <h2>This Week's Notifications</h2>
  {thisWeekNotifications.map((user, i) => (
    // Render notification components here
  ))}
          {/* </div> */}
              {segment === "booking" && (
                <div>
                  {NotificationsAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#CCCCCC",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  {notificationBooking.map((user, i) => (

                    <IonGrid key={i} onClick={() => { notificationReadStatus(user, 'bookings'); }} className={user.readflag == 1 ? 'dark-notificationunread' : 'notificationread'}>
                      <IonRow>
                        <IonCol size-md="1" size="2">
                          <IonAvatar className="notification-img">
                            {user.listing_style.image1 == null || user.listing_style.image1 == "" ? (
                              <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                            ) : (
                              <IonImg src={user.listing_style.image1} alt="User Image" />
                            )}
                          </IonAvatar>
                        </IonCol>
                        <IonCol size-md="11" size="10">
                          <IonLabel className="dark-notification-msg">
                            {user.notification_message}
                          </IonLabel>
                          <IonLabel className="dark-notification-date-time">
                            {user.formatted_date}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  ))}
                </div>
              )}
              {segment === "general" && (
                <div>
                  {notificationGeneral.length == 0 && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#CCCCCC",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  {notificationGeneral.map((user: any, i: any) => (
                    <Link style={{ color: "#515151" }} className="link-decoration" onClick={() => { notificationReadStatus(user, 'general') }} to={{
                      pathname: '/notificationDetail',
                      state: { data: user }
                    }}>

                      <IonGrid key={i}
                        className={user.readflag == 1 ? 'dark-notificationunread' : 'notificationread'}
                        onClick={() => { notificationReadStatus(user, 'general') }}
                      >
                        <IonRow>
                          <IonCol size-md="1" size="2">
                            <IonAvatar className="notification-img">
                              {user.image == null || user.image == "" ? (
                                <IonImg src="https://afh.cloud/asset/img/afh_icon_image.jpg" alt="No Image" />
                              ) : (
                                <IonImg src={user.image} alt="User Image" />
                              )}
                            </IonAvatar>
                          </IonCol>
                          <IonCol size-md="11" size="10">
                            <IonLabel className="dark-notification-msg">
                              {user.notification_message}
                            </IonLabel>
                            <IonLabel className="dark-notification-date-time">
                              {user.formatted_date}
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </Link>
                  ))}
                </div>
              )}
              {segment === "all" && (
                <div>
                  {NotificationsAllAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#CCCCCC",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  {notificationList.map((user: any, i: any) => (
                    <IonGrid key={i}
                      className={user.readflag == 1 ? 'dark-notificationunread' : 'notificationread'}
                      onClick={() => {
                        if (user.bookingid == null) {
                          redirectND(user);
                        } else {
                          notificationReadStatus(user, 'all');
                        }
                      }}>
                      <IonRow>
                        <IonCol size-md="1" size="2">
                          <IonAvatar className="notification-img">
                            {user.image == null || user.image == "" ? (
                              <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                            ) : (
                              <IonImg src={user.image} alt="User Image" />
                            )}
                          </IonAvatar>
                        </IonCol>
                        <IonCol size-md="11" size="10">
                          <IonLabel className="dark-notification-msg">
                            {user.notification_message}
                          </IonLabel>
                          <IonLabel className="dark-notification-date-time">
                            {user.formatted_date}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  ))}
                </div>
              )}
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-inactive" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => {setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");}}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonToast
                  isOpen={showToast}
                  onDidDismiss={() => setShowToast(false)}
                  message={errorMessage}
                  duration={3000}
                  color="danger"
                />
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center notifications-title">Notifications</IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/login"
                routerDirection="none"
              >
                {/* <IonIcon
  className="fav header-icons"
  src="assets/imgs/icons/backArrow.svg"
  slot="end"
/> */}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader><IonContent fullscreen className="notifications ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent></>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center notifications-title">Notifications</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                >
                  {/* <IonIcon
                className="fav header-icons"
                src="assets/imgs/icons/backArrow.svg"
                slot="end"
              /> */}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="notifications ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div className="section animate__animated animate__fadeInUp">

              {segment === "booking" && (
                <div>
                  {NotificationsAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {notificationBooking.map((user, i) => (
                        <IonCol size-md="6" size="12" key={i}>
                          <IonGrid
                            onClick={() => { notificationReadStatus(user, 'bookings'); }}
                            className={user.readstatus == 1 ? 'notificationunread' : 'notificationread'}
                          >
                            <IonRow>
                              <IonCol size-md="1" size="2">
                                <IonAvatar className="notification-img">
                                  {user.bookings && user.bookings.listing_style.image1 == null || user.bookings.listing_style.image1 == "" ? (
                                    <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                                  ) : (
                                    <IonImg src={user.bookings.listing_style.image1} alt="User Image" />
                                  )}
                                </IonAvatar>
                              </IonCol>
                              <IonCol size-md="11" size="10">
                                <IonLabel className="notification-msg">
                                  {user.message}
                                </IonLabel>
                                <IonLabel className="notification-date-time">
                                  {user.created}
                                </IonLabel>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment === "general" && (
                <div>
                  {NotificationsGeneralAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {notificationGeneral.map((user: any, i: any) => (
                        <IonCol size-md="6" size="12">
                          <Link style={{ color: "#515151" }} className="link-decoration" onClick={() => { notificationReadStatus(user, 'general') }} to={{
                            pathname: '/notificationDetail',
                            state: { data: user }
                          }}>

                            <IonGrid key={i}
                              className={user.readflag == 1 ? 'notificationunread' : 'notificationread'}
                              onClick={() => { notificationReadStatus(user, 'general') }}
                            >
                              <IonRow>
                                <IonCol size-md="1" size="2">
                                  <IonAvatar className="notification-img">
                                    {user.image == null || user.image == "" ? (
                                      <IonImg src="assets/imgs/icons/light-profile.svg" alt="No Image" />
                                    ) : (
                                      <IonImg src={user.image} alt="User Image" />
                                    )}
                                  </IonAvatar>
                                </IonCol>
                                <IonCol size-md="11" size="10">
                                  <IonLabel className="notification-msg notification-msg-general">
                                    {user.message}
                                  </IonLabel>
                                  <IonLabel className="notification-date-time ">
                                    {user.created}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </Link>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment === "all" && (
                <div>
                  {NotificationsAllAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {notificationList.map((user: any, i: any) => (
                        <IonCol key={i} size-md="6" size="12">
                          <IonGrid
                            className={user.readstatus == 1 ? 'notificationunread' : 'notificationread'}
                            onClick={() => {
                              if (user.bookingid === null) {
                                redirectND(user);
                              } else {
                                notificationReadStatus(user, 'all');
                              }
                            }}
                          >
                            <IonRow>
                              <IonCol size-md="1" size="2">
                                <IonAvatar className="notification-img">
                                  {user.bookings == null || user.bookings.listing_style.image1 == null || user.bookings.listing_style.image1 == "" ? (
                                    <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                                  ) : (
                                    <IonImg src={user.bookings.listing_style.image1} alt="User Image" />
                                  )}
                                </IonAvatar>
                              </IonCol>
                              <IonCol size-md="11" size="10">
                                <IonLabel className="notification-msg">
                                  {user.message}
                                </IonLabel>
                                <IonLabel className="notification-date-time">
                                  {user.created}
                                </IonLabel>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger" />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => {setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");}} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      <WatiChat />
    </IonPage>
  );
};

export default Notifications;
