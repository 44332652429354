import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  SearchbarChangeEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";

import { useEffect, useState, useRef } from "react";
import "./Expenses.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
// import { Geolocation } from '@ionic-native/geolocation';
import { Network } from '@capacitor/network';
import WatiChat from "../../components/WatiChat";

const Expenses: React.FC = () => {

  const darkModeValue = localStorage.getItem("darkMode");

  const history = useHistory();

  // const [latitude, setLatitude] = useState<number | null>(null);
  // const [longitude, setLongitude] = useState<number | null>(null);

  const [segment, setSegment] = useState('unconfirmed');

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [unconfirmedSearchQuery, setUnconfirmedSearchQuery] = useState<string>('');
  const [expiredSearchQuery, setExpiredSearchQuery] = useState<string>('');

  const [isBookingsLoading, setIsBookingsLoading] = useState(false);


  const [bookingEmptyMsg, setBookingEmptyMsg] = useState(false);
  const [unconfirmedBookingEmptyMsg, setUnconfirmedBookingEmptyMsg] = useState(false);
  const [expiredBookingEmptyMsg, setExpiredBookingEmptyMsg] = useState(false);


  const [booking, setbooking] = useState<Array<any>>([]);
  const [unconfirmedBooking, setUnconfirmedBooking] = useState<Array<any>>([]);
  const [page, setPage] = useState<string>('');
  const [expiredBooking, setExpiredBooking] = useState<Array<any>>([]);


  const [loading, setLoading] = useState(true);
  const [backdropVisible, setBackdropVisible] = useState(false);

  const [confirmedCount, setConfirmedCount] = useState(0);
  const [unConfirmedCount, setUnConfirmedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  const [confirmedSortCount, setConfirmedSortCount] = useState(0);
  const [unConfirmedSortCount, setUnConfirmedSortCount] = useState(0);

  const [sortOption, setSortOption] = useState('datetime');
  const [confirmSortOption, setConfirmSortOption] = useState('datetime');
  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
  const [showConfirmPopover, setShowConfirmPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });

  const currentDate = new Date();
  const validInput = /^[A-Za-z0-9 \n]*$/;
  const latestInputRejectDescriptionValue = useRef('');

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');

  const [supendedTitle, setSupendedTitle] = useState('');

  const [showRejectModal, setShowRejectModal] = useState(false);

  const [showConfirmedModal, setShowConfirmedModal] = useState(false);
  const [showRejectCnfmModal, setShowRejectCnfmModal] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [bookingStatus, setBookingStatus] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [isRejectChecked, setIsRejectChecked] = useState(false);
  const [rejectname, setRejectName] = useState("");
  const [rejecttextarea, setRejectTextArea] = useState("");
  const [textareaErrorMessage, setTextareaErrorMessage] = useState('');
  const [cnfmNameErrorMessage, setCnfmNameErrorMessage] = useState('');
  const [rejectNameErrorMessage, setRejectNameErrorMessage] = useState('');
  const latestInputRejectValue = useRef('');

  const closeShowModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
  
    setUnauthorizedHandler(() => {
      setSupendedMsg("Your account is inactive, please contact support.");
      setSupendedTitle("Inactive");
      setShowSuspendedModal(true);
    });

    return () => {
      setUnauthorizedHandler(() => {});
    };
  }, []);

  const closeRejectModal = () => {
    // setRejectName("");
    // setSelectedValue("");
    // setRejectTextArea("");
    // setTextareaErrorMessage('');
    // setCnfmNameErrorMessage('');
    // setRejectNameErrorMessage('');
    // latestInputAcceptValue.current = "";
    // latestInputRejectDescriptionValue.current = "";
    // latestInputRejectValue.current = "";
    // setIsRejectChecked(false);
    setShowRejectModal(false);
  };

  const handleOkayClick = () => {
    if (selectedValue == "" || latestInputRejectDescriptionValue.current == "") {
      setErrorMessage("Please enter both the inputs");
      setShowToast(true);
    } else {
      setShowRejectCnfmModal(true);
      setShowRejectModal(false);
    }
  }

  const handlesetRejectTextAreaInput = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setRejectTextArea(inputValue);
      latestInputRejectDescriptionValue.current = inputValue;
      setTextareaErrorMessage("");  // clear the error message
    } else if (inputValue.startsWith(' ')) {
      setTextareaErrorMessage("Input cannot start with a space.");
    } else if (!validInput.test(inputValue)) {
      setTextareaErrorMessage("Invalid input. Only alphanumeric characters, spaces, and newlines are allowed.");
    } else {
      setRejectTextArea(inputValue);
      latestInputRejectDescriptionValue.current = inputValue;
      setTextareaErrorMessage("");  // clear the error message
    }
  }

  const openAcceptBooking = (user: any) => {
    setBookingStatus(user.status);
    localStorage.setItem("bookingIdPopup", user.booking_id);
    setShowModal(true);
  }

  const openRejectBooking = (user: any) => {
    setBookingStatus(user.status);
    localStorage.setItem("bookingIdPopup", user.booking_id);
    setShowRejectModal(true);
  }

  const goBack = () => {
    history.goBack();
  };

  const redirectionNotification = () => {
    if (page == "confirmed") {
      setPage('');
      localStorage.setItem("upcomingBookings", "upcoming");
    } else if (page == "unConfirmed") {
      setPage('');
      localStorage.setItem("viewallbookings", "viewall");
    } else if (page == "expired") {
      setPage('');
      localStorage.setItem("expired", "completed");
    }
  }

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);


  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {

      setNetworkStatus(status.connected);
    });
  }, []);

  useIonViewWillEnter(() => {
    // getLocation();
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    } else {
    BookingCountFunction();
    if (localStorage.getItem("viewallbookings") === "viewall") {
      setSegment('unconfirmed');
      setSortOption('datetime');
      unConfirmFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else if (localStorage.getItem("upcomingBookings") === "upcoming") {
      setSegment('confirmed');
      setSortOption('datetime');
      confirmFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else if (localStorage.getItem("expired") === "completed") {
      setSegment('expired');
      expiredFunction();
      setSortOption('datetime');
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else {
      if (segment == "confirmed") {
        setSortOption('datetime');
        confirmFunction();
        setSearchQuery('');
        setUnconfirmedSearchQuery('');
        setExpiredSearchQuery('');
      } else if (segment == "unconfirmed") {
        setSortOption('datetime');
        unConfirmFunction();
        setSearchQuery('');
        setUnconfirmedSearchQuery('');
        setExpiredSearchQuery('');
      } else {
        expiredFunction();
        setSortOption('datetime');
        setSearchQuery('');
        setUnconfirmedSearchQuery('');
        setExpiredSearchQuery('');
      }
    }
  }
  }, [])

  const closeRejectCnfmModal = () => {
    setRejectName("");
    setSelectedValue("");
    setRejectTextArea("");
    setTextareaErrorMessage('');
    setCnfmNameErrorMessage('');
    setRejectNameErrorMessage('');
    latestInputRejectDescriptionValue.current = "";
    latestInputRejectValue.current = "";
    setIsRejectChecked(false);
    setShowRejectCnfmModal(false)
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const confirm = () => {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig.patch(`/bookings/${localStorage.getItem("bookingId")}`, {
      status: 4
    }, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.status == true) {
          setShowModal(false);
          localStorage.setItem("confirmed", "Confirmed")
          localStorage.setItem('bookingId', res.data.data.bookingid);
          setBookingStatus(res.data.data.status);
          setbooking(res.data.data);
          setLoading(false);
          setShowConfirmedModal(true);
          setBackdropVisible(false);
        } else {
          setErrorMessage(res.data.data.response);
          setShowToast(true);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }
  const reject = () => {
    setLoading(true);
    setBackdropVisible(true);
    apiConfig.patch(`/bookings/${localStorage.getItem("bookingId")}`, {
      status: 5,
      reject_reason: selectedValue,
      reject_notes: latestInputRejectDescriptionValue.current
    }, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.status == true) {
          setIsRejectChecked(false);
          setbooking(res.data.details);
          setBookingStatus(res.data.data.status);
          setShowRejectCnfmModal(false);
          setShowRejectedModal(true);
          setRejectName("");
          setSelectedValue("");
          setRejectTextArea("");
          setTextareaErrorMessage('');
          setCnfmNameErrorMessage('');
          setRejectNameErrorMessage('');
          latestInputRejectDescriptionValue.current = "";
          latestInputRejectValue.current = ""
          setbooking([]);
          // unConfirmFunction();
          // setShowRejectedAlert(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setErrorMessage(res.data.data.response);
          setShowToast(true);
          setLoading(false);
          setBackdropVisible(false);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const BookingCountFunction = () => {
    apiConfig.get(`/booking_counts?partner_id=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setConfirmedCount(res.data.data.confirmed_bookings);
        setUnConfirmedCount(res.data.data.unconfirmed_bookings);
        setCompletedCount(res.data.data.completed_booking);
      })
      .catch((e) => {
        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  // const getLocation = async () => {
  //   try {
  //     const position = await Geolocation.getCurrentPosition();
  //     setLatitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  //   } catch (error) {
  //   }
  // };

  const confirmFunction = () => {
    localStorage.setItem("upcomingBookings", "");
    setBookingEmptyMsg(false);
    setbooking([]);
    setLoading(true);
    BookingCountFunction();
    setBackdropVisible(true);
    setTimeout(() => {
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 5000);
    apiConfig.get(`/bookings?status=4&id=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setConfirmedCount(res.data.data.length);
        if (res.data.data.length === 0) {
          setBookingEmptyMsg(true);
          setbooking(res.data.data);
          setLoading(false);
          setBackdropVisible(false);
          setIsBookingsLoading(true);
        } else {
          setBookingEmptyMsg(false);
          setbooking(res.data.data);
          setPage("confirmed");
          setLoading(false);
          setBackdropVisible(false);
          setIsBookingsLoading(false);
        }

      })
      .catch((e: any) => {
        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }
  const getBookingId = (e: any) => {
    localStorage.setItem('bookingId', e);
  }
  const unConfirmFunction = () => {
    localStorage.setItem("viewallbookings", "");
    setUnconfirmedBookingEmptyMsg(false);
    setUnconfirmedBooking([]);
    BookingCountFunction();
    setLoading(true);
    setBackdropVisible(true);
    setTimeout(() => {
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 5000);
    apiConfig.get(`/bookings?status=3&id=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setUnConfirmedCount(res.data.data.length);
        if (res.data.data.length === 0) {
          setUnconfirmedBookingEmptyMsg(true);
          setUnconfirmedBooking([]);
          setLoading(false);
          setBackdropVisible(false);
          setIsBookingsLoading(true);
        } else {
          setPage("unConfirmed");
          setUnconfirmedBookingEmptyMsg(false);
          setUnconfirmedBooking(res.data.data);
          setLoading(false);
          setBackdropVisible(false);
          setIsBookingsLoading(false);

        }
      })
      .catch((e) => {
        if (e.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
          setShowSuspendedModal(true);
        }
        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }
  const expiredFunction = () => {
    localStorage.setItem("expired", "");
    setExpiredBookingEmptyMsg(false);
    setExpiredBooking([]);
    // BookingCountFunction();
    setLoading(true);
    setBackdropVisible(true);
    const timeoutId = setTimeout(() => {
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 1000);
    apiConfig.get(`/bookings?status=1&id=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        clearTimeout(timeoutId);
        setCompletedCount(res.data.data.length);
        if (res.data.data.length === 0) {
          setExpiredBookingEmptyMsg(true);
          setExpiredBooking(res.data.data);
          setLoading(false);
          setBackdropVisible(false);
          setIsBookingsLoading(true);
        } else {
          setExpiredBookingEmptyMsg(false);
          setExpiredBooking(res.data.data);
          setPage("expired");
          setLoading(false);
          setBackdropVisible(false);
          setIsBookingsLoading(false);
        }

      })
      .catch((e: any) => {
        clearTimeout(timeoutId);
        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const handleSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setSearchQuery(value ?? '');
  };
  const handleUnconfirmedSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setUnconfirmedSearchQuery(value ?? '');
  };
  const handleExpiredSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setExpiredSearchQuery(value ?? '');
  };
  const handleInputChange = (event: any) => {
    setIsBookingsLoading(true);
    if (event.detail.value == "confirmed") {
      setSortOption('datetime');
      confirmFunction();
      setSegment("confirmed");
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else if (event.detail.value == "unconfirmed") {
      setSortOption('datetime');
      setSegment("unconfirmed");
      unConfirmFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else {
      setSortOption('datetime');
      setSegment("expired");
      expiredFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    }
  };
  function parseDate(input: string) {
    if (typeof input !== 'string') {
      throw new Error('Input must be a valid string.');
    }

    const parts = input.match(/(\d+)/g);
    if (!parts || parts.length < 5) {
      return new Date(NaN);
    }
    return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]), parseInt(parts[3]), parseInt(parts[4]));
  }
  const handleConfirmRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      confirmFunction();
      event.detail.complete();
    }, 1000);
  };
  const handleUnconfirmRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      unConfirmFunction();
      event.detail.complete();
    }, 1000);
  };
  const handleExpiredRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      expiredFunction();
      event.detail.complete();
    }, 1000);
  };
  const handleConfirmedFilter = (bookingList: any, filterOption: string, currentDate: Date) => {
    let filteredList = bookingList;

    if (filterOption === 'thisWeek') {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.start_date_time);
        return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
      });
    } else if (filterOption === 'thisMonth') {
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.start_date_time);
        return (
          bookingDate.getMonth() === currentDate.getMonth() &&
          bookingDate.getFullYear() === currentDate.getFullYear()
        );
      });
    } else if (filterOption === 'thisYear') {
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.start_date_time);
        return bookingDate.getFullYear() === currentDate.getFullYear();
      });
    } else if (filterOption === 'status') {
      filteredList = bookingList.filter((user: any) => user.status == "4");
    } else {
      filteredList = bookingList;
    }

    filteredList.sort((a: any, b: any) => {
      const dateA = parseDate(a.start_date_time);
      const dateB = parseDate(b.start_date_time);
      return dateA.getTime() - dateB.getTime();
    });

    setTimeout(() => {
      setConfirmedCount(filteredList.length);
      setConfirmedSortCount(filteredList.length);
    }, 1000);
    return filteredList;
  };

  const handleFilter = (bookingList: any, filterOption: string, currentDate: Date) => {
    let filteredList = bookingList;

    if (filterOption === 'thisWeek') {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.start_date_time);
        return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
      });
    } else if (filterOption === 'thisMonth') {
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.start_date_time);
        return (
          bookingDate.getMonth() === currentDate.getMonth() &&
          bookingDate.getFullYear() === currentDate.getFullYear()
        );
      });
    } else if (filterOption === 'thisYear') {

      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.start_date_time);
        return bookingDate.getFullYear() === currentDate.getFullYear();
      });
    } else if (filterOption === 'status') {
      filteredList = bookingList.filter((user: any) => user.status == "7");
    } else if (filterOption === 'unconfirmed') {
      filteredList = bookingList.filter((unconfirmed: any) => unconfirmed.status == "3");
    } else {

      filteredList = bookingList;
    }

    filteredList.sort((a: any, b: any) => {
      const dateA = parseDate(a.start_date_time);
      const dateB = parseDate(b.start_date_time);
      return dateA.getTime() - dateB.getTime();
    });
    setTimeout(() => {
      setUnConfirmedSortCount(filteredList.length);
      setUnConfirmedCount(filteredList.length);
    }, 1000);
    return filteredList;
  };

  const handleExpiredSort = (bookingList: any) => {
    const sortedList = [...bookingList];
    // sortedList.sort((a, b) => {
    //   if (sortOption === 'status') {
    //     const statusA = String(a.status);
    //     const statusB = String(b.status);
    //     return statusB.localeCompare(statusA);
    //   } else if (sortOption === 'datetime') {
    //     // Using the helper function to parse date strings
    //     const dateA = parseDate(a.lead_details.start_date_time);
    //     const dateB = parseDate(b.lead_details.start_date_time);
    //     return dateB.getTime() - dateA.getTime();
    //   } else {
    //     return a.id - b.id;
    //   }
    // });
    return sortedList;
  };

  return (
    <IonPage>

      {networkStatus == false && darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ textAlign: "center" }}>
                Bookings
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                  onClick={redirectionNotification}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="expenses">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true &&
        darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ textAlign: "center" }}>
                Bookings
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                  onClick={redirectionNotification}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="expenses">
            {segment === "confirmed" && (
              <IonRefresher slot="fixed" onIonRefresh={handleConfirmRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "unconfirmed" && (
              <IonRefresher slot="fixed" onIonRefresh={handleUnconfirmRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "expired" && (
              <IonRefresher slot="fixed" onIonRefresh={handleExpiredRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {segment === "confirmed" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size-md='11' size="10">
                      <IonSearchbar
                        placeholder='Confirmed Bookings'
                        style={{ height: "40px" }}
                        className="search-bar"
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleSearch}
                      ></IonSearchbar>
                    </IonCol>
                    <IonCol size-md='1' size="2" style={{ display: "flex" }}>
                      <IonButtons className="sort-btn" onClick={(e) => setShowConfirmPopover({ open: true, event: e.nativeEvent })}>
                        <IonButton>
                          <IonIcon
                            style={{ fontSize: "22px" }}
                            className="fav"
                            src="assets/imgs/icons/sort.svg" />
                          <span className="sort-count-badge">{confirmedSortCount}</span>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
              {segment === "unconfirmed" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size-md='11' size="10">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        className="search-bar"
                        placeholder='Unconfirmed Bookings'
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleUnconfirmedSearch}
                      ></IonSearchbar>
                    </IonCol>
                    <IonCol size-md='1' size="2" style={{ display: "flex" }}>
                      <IonButtons className="sort-btn" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
                        <IonButton>
                          <IonIcon
                            style={{ fontSize: "22px" }}
                            className="fav"
                            src="assets/imgs/icons/sort.svg" />
                          <span className="sort-count-badge">{unConfirmedSortCount}</span>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>

              )}
              {segment === "expired" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size="12">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        className="search-bar"
                        placeholder='Expired Bookings'
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleExpiredSearch}
                      ></IonSearchbar>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment className='fontName' value={segment} onIonChange={handleInputChange}>
                  <IonSegmentButton className='fontName custom-segment-button-unconfirmed' value="unconfirmed">
                    <h6 className="light-mode-segements">Unconfirmed <span className='spanCount'>{unConfirmedCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton className='fontName custom-segment-button-confirmed' value="confirmed">
                    <h6 className="light-mode-segements">Confirmed <span className='spanCount'>{confirmedCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton className='fontName custom-segment-button-expired' value="expired">
                    <h6 className="light-mode-segements">Completed <span className='spanCount'>{completedCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              {segment === "confirmed" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {bookingEmptyMsg == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there are currently no confirmed bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = booking.filter(user =>
                            user.quote.customername?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.listing_style.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.listing_categories.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.listing_subcategories.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.booking_id?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.quote.mobile?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            (user.book_date?.includes(searchQuery) ?? "") ||
                            (user.book_time?.includes(searchQuery) ?? "")
                          );
                          const sortedFilteredBookings = handleConfirmedFilter(filteredBookings, confirmSortOption, currentDate);

                          return sortedFilteredBookings.length > 0 ? (

                            sortedFilteredBookings.map((user: any, i: any) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard key={i} className="overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.listing_style && user.listing_style.image1 ? user.listing_style.image1 : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-flex d-first-div">
                                        <IonLabel className="first-title">{user.listing_style?.title || "N/A"}</IonLabel>
                                          <br />
                                          <IonLabel className="cat-subcat-title">{user.listing_categories.title} &gt; {user.listing_subcategories.title}</IonLabel>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_date} <span style={{ color: '#12877733' }}>|</span></IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_time}</IonLabel>

                                          </div>
                                          <div className="div-align d-d-block">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.quote.customername}</IonLabel>
                                          </div>
                                          <a style={{ textDecoration: "none" }} href={"tel:" + user.quote.mobile}>
                                            <img className="call-icon" src="assets/imgs/icons/phone.svg" />
                                          </a>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="10">
                                        <div className="div-align">
                                          <IonLabel className="booking-id-chip">{user.booking_id}</IonLabel>
                                        </div>
                                        <div className="div-align" style={{ marginLeft: '2%' }}>
                                          {user.status == "4" && (
                                            <IonLabel className="modified-chips">Confirmed</IonLabel>
                                          )}
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="2">
                                        <div className="div-align">
                                          <IonLabel className="label-align pound"> £{(parseFloat(user.subtotal) + parseFloat(user.travelcost)).toFixed(2)}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size="12" col-12>
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6
                                      style={{
                                        color: "#515151",
                                        textAlign: "center",
                                        marginTop: "11%",
                                      }}
                                      className="fontName nocardh6"
                                    >
                                       Sorry, there are currently no confirmed bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()

                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment == "unconfirmed" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {isBookingsLoading == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there are currently no new bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = unconfirmedBooking.filter(user =>
                            user.quote.customername?.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.listing_style.title?.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.listing_categories.title?.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.listing_subcategories.title?.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.booking_id?.toString().toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.quote.mobile?.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            (user.book_date?.includes(unconfirmedSearchQuery) ?? "") ||
                            (user.book_time?.includes(unconfirmedSearchQuery) ?? "")
                          );

                          const sortedFilteredBookings = handleFilter(filteredBookings, sortOption, currentDate);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user: any, i: any) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.listing_style && user.listing_style.image1 ? user.listing_style.image1 : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-flex d-first-div">
                                          <IonLabel className="first-title">{user.listing_style?.title || "N/A"}</IonLabel>
                                          <br />
                                          <IonLabel className="cat-subcat-title">{user.listing_categories.title} &gt; {user.listing_subcategories.title}</IonLabel>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_date} <span style={{ color: '#12877733' }}>|</span></IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_time}</IonLabel>
                                          </div>
                                          <div className="div-align d-d-block">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.quote.customername}</IonLabel>
                                          </div>
                                        </div>

                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="10">
                                        <div className="div-align">
                                          <IonLabel className="booking-id-chip">{user.booking_id}</IonLabel>
                                        </div>
                                        <div className="div-align" style={{ marginLeft: '2%' }}>
                                          {user.status == "3" && (
                                            <IonLabel className="unconfirmed-chips">Unconfirmed</IonLabel>
                                          )}
                                          {user.status == "7" && (
                                            <IonLabel className="modified-chips">Modified</IonLabel>
                                          )}
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="2">
                                        <div className="div-align">
                                          <IonLabel className="label-align pound"> £{(parseFloat(user.subtotal) + parseFloat(user.travelcost)).toFixed(2)}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size="12">
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                      {/* <IonCol size="4">
                                        <IonButton expand="block" onClick={() => { openAcceptBooking(user); getBookingId(user.id); }} className="accept-btn-bkng">
                                          Accept
                                        </IonButton>
                                      </IonCol>
                                      <IonCol size="4">
                                        <IonButton expand="block" onClick={() => { openRejectBooking(user); getBookingId(user.id); }} className="reject-bkg-btn">
                                          Reject
                                        </IonButton>
                                      </IonCol> */}
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>

                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6
                                      style={{
                                        color: "#515151",
                                        textAlign: "center",
                                        marginTop: "11%",
                                      }}
                                      className="fontName nocardh6"
                                    >
                                      Sorry, there are currently no new bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()
                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment == "expired" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {expiredBookingEmptyMsg == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there are currently no completed bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = expiredBooking
                            .filter(user =>
                              user.quote.customername?.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.listing_style.title?.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.listing_categories.title?.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.listing_subcategories.title?.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.booking_id?.toString().toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.quote.mobile?.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              (user.book_date?.includes(expiredSearchQuery) ?? "") ||
                              (user.book_time?.includes(expiredSearchQuery) ?? "")
                            );
                          const sortedFilteredBookings = handleExpiredSort(filteredBookings);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user, i) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        {/* {user.status == "3" && (
                                    <div className="ribbon">Unconfirmed</div>
                                  )}
                                  {user.status == "7" && (
                                    <div className="ribbon-modified">Modified</div>
                                  )} */}
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.listing_style && user.listing_style.image1 ? user.listing_style.image1 : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-flex d-first-div">
                                        <IonLabel className="first-title">{user.listing_style?.title || "N/A"}</IonLabel>
                                          <br />
                                          <IonLabel className="cat-subcat-title">{user.listing_categories.title} &gt; {user.listing_subcategories.title}</IonLabel>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_date} <span style={{ color: '#12877733' }}>|</span></IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_time}</IonLabel>
                                          </div>
                                          <div className="div-align d-d-block">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.quote.customername}</IonLabel>
                                          </div>
                                        </div>

                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="10">
                                        <div className="div-align">
                                          <IonLabel className="booking-id-chip">{user.booking_id}</IonLabel>
                                        </div>
                                        <div className="div-align" style={{ marginLeft: '2%' }}>
                                          {user.status == "3" && (
                                            <IonLabel className="unconfirmed-chips">Unconfirmed</IonLabel>
                                          )}
                                          {user.status == "7" && (
                                            <IonLabel className="modified-chips">Modified</IonLabel>
                                          )}
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="2">
                                        <div className="div-align">
                                          <IonLabel className="label-align pound"> £{(parseFloat(user.subtotal) + parseFloat(user.travelcost)).toFixed(2)}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size="12" col-12>
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6
                                      style={{
                                        color: "#515151",
                                        textAlign: "center",
                                        marginTop: "11%",
                                      }}
                                      className="fontName nocardh6"
                                    >
                                      Sorry, there are currently no completed bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()
                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
            <IonModal id="example-modal" isOpen={showModal} backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12">
                      <IonImg src="assets/imgs/icons/questionMark.svg" alt="questionMark" className="tick-img"></IonImg>
                      <div className="bookingid-label label-div">
                        <p className="booking-id-chip">{localStorage.getItem("bookingIdPopup")}</p>
                        {bookingStatus == 3 &&
                          <p className="unconfirmed-chip">Unconfirmed</p>
                        }
                        {bookingStatus == "4" &&
                          <p className="confirmed-chip">Confirmed</p>
                        }
                        {bookingStatus == "7" &&
                          <p className="modified-chip">Modified</p>
                        }
                        {bookingStatus == "1" &&
                          <p className="expired-chip">Expired</p>
                        }
                      </div>
                      <div className="model-sub-div">
                        <IonLabel className='fontName model-sub-title'>Are you sure?</IonLabel>
                      </div>
                      <div className='fontName model-sub-div'>
                        <IonLabel className="model-note"> If you’re ok with the customer’s requirements, please confirm the booking</IonLabel>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeShowModal} className='fontName no-btn' type='submit'>
                        Go Back
                      </IonButton>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={confirm} className='fontName yes-accept-btn' id="open-customa-dialog" expand="block">
                        Accept Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal isOpen={showConfirmedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/tick.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>Booking Confirmed!</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>Thank you for confirming the Booking ID: {localStorage.getItem("bookingIdPopup")}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowConfirmedModal(false); unConfirmFunction(); }} expand="block">
                        Back to Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal id="example-modal-reject" isOpen={showRejectModal} backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/rejectIcon.svg" alt="rejectIcon" className="tick-img"></IonImg>
                      <div className="bookingid-label label-div">
                        <p className="booking-id-chip">{localStorage.getItem("bookingIdPopup")}</p>
                        {bookingStatus == "3" &&
                          <p className="unconfirmed-chip">Unconfirmed</p>
                        }
                        {bookingStatus == "4" &&
                          <p className="confirmed-chip">Confirmed</p>
                        }
                        {bookingStatus == "7" &&
                          <p className="modified-chip">Modified</p>
                        }
                        {bookingStatus == "1" &&
                          <p className="expired-chip">Expired</p>
                        }
                      </div>
                      <div className="model-sub-div">
                        <IonLabel className='fontName model-sub-title'>Are you sure?</IonLabel>
                        <br />
                        <IonLabel className="reject-sentence">Please let us know reason for rejection</IonLabel>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonSelect interface="popover" className='fontName form-control-model' placeholder="--select reason--" value={selectedValue} onIonChange={handleSelectChange}>
                          <IonSelectOption value="">Choose Option</IonSelectOption>
                          <IonSelectOption value="Not available">Not available</IonSelectOption>
                          <IonSelectOption value="Taken own booking">Taken own booking</IonSelectOption>
                          <IonSelectOption value="Illness">Illness</IonSelectOption>
                        </IonSelect>
                        <label className="label-outline-model" htmlFor="textAreaExample">Reason for Rejection</label>
                      </div>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <div style={{ marginTop: "4%" }} className="model-container">
                        <textarea
                          placeholder="Type something here"
                          rows={4}
                          className='fontName form-control-model text-area-css'
                          value={rejecttextarea}
                          onChange={handlesetRejectTextAreaInput}
                        ></textarea>
                        <label className="label-outline-model" htmlFor="textAreaExample">Notes</label>
                      </div>
                      <div className='fontName emailErrorMsg'>{textareaErrorMessage}</div>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeRejectModal} className='fontName no-btn' type='submit'>
                        No
                      </IonButton>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={handleOkayClick} className='fontName yes-reject-btn' id="open-customa-dialog" expand="block">
                        Yes, Reject
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal isOpen={showRejectCnfmModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>Do you want to Reject?</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>Are you sure you want to reject the Booking ID: {localStorage.getItem("bookingIdPopup")}?</IonLabel>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={closeRejectCnfmModal} className='fontName reject-btn' type='submit'>
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={() => { reject() }} className='fontName accept-btn' id="open-customa-dialog" expand="block">
                        Okay
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal isOpen={showRejectedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/cancel.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>Booking Rejected!</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>Booking ID: {localStorage.getItem("bookingIdPopup")} has been rejected as per your request.</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={() => { setShowRejectedModal(false); unConfirmFunction(); }} className='fontName booking-details-btn' expand="block">
                        Back to Booking
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonPopover
              isOpen={showConfirmPopover.open}
              event={showConfirmPopover.event}
              onDidDismiss={() => setShowConfirmPopover({ open: false, event: undefined })}
            >
              <IonItem button lines="none"
                className="popup-ion-item"
                onClick={() => { setConfirmSortOption('datetime'); setShowConfirmPopover({ open: false, event: undefined }); }}
              >
                {confirmSortOption === 'datetime' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Default</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisWeek' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisWeek'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisWeek' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Week</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisMonth' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisMonth'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisMonth' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Month</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisYear' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisYear'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisYear' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Year</IonLabel>
              </IonItem>
            </IonPopover>
            <IonPopover
              isOpen={showPopover.open}
              event={showPopover.event}
              onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
            >
              <IonItem button lines="none"
                className="popup-ion-item"
                onClick={() => { setSortOption('datetime'); setShowPopover({ open: false, event: undefined }); }}
              >
                {sortOption === 'datetime' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Default</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'status' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('status'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'status' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Modified</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'unconfirmed' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('unconfirmed'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'unconfirmed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Unconfirmed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisWeek' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('thisWeek'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisWeek' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Week</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisMonth' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('thisMonth'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisMonth' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Month</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisYear' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('thisYear'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisYear' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Year</IonLabel>
              </IonItem>
            </IonPopover>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => {setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");}} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      <WatiChat />
    </IonPage >
  );
};

export default Expenses;
