import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
    IonSelectOption,
    IonSelect,
    IonPicker, PickerColumn, IonCard, IonToggle
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import apiConfig, { setUnauthorizedHandler } from "../../apiConfig";
import "./Newavailpage.css";
import { Network } from '@capacitor/network';
import React from "react";
import WatiChat from "../../components/WatiChat";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

interface OwnProps extends RouteComponentProps { }
const NewAvailability: React.FC = () => {
    const darkModeValue = localStorage.getItem("darkMode");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    const [networkStatus, setNetworkStatus] = useState<any>(true);

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);

    const [showToasSuccess, setShowToastSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [activePicker, setActivePicker] = useState<{ type: string, dayIndex: number, timeIndex: number }>({ type: '', dayIndex: -1, timeIndex: -1 });
    const [availId, setAvailId] = useState(null);
    const [availability, setAvailability] = useState<Array<any>>([]);
    const [type, setType] = useState('');
    const [selectedHourIndex, setSelectedHourIndex] = useState(0);
    const [selectedMinuteIndex, setSelectedMinuteIndex] = useState(0);

    const [dropdownShow, setDropdownShow] = useState<{ [key: number]: string }>({});


    // Unavailabledates


    const [selectedDatesList, setSelectedDatesList] = useState<string[]>([]);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [clickedDates, setClickedDates] = useState<string[]>([]);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [suspendedMsg, setSuspendedMsg] = useState("");
    const [suspendedTitle, setSuspendedTitle] = useState("");
    const [currentMonth, setCurrentMonth] = useState(new Date());


    useIonViewWillEnter(() => {
        if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
            history.push('/login');
          } else {
            setCurrentMonth(new Date());
            getUnAvailSlot();
          }
    }, []);

    useEffect(() => {

        setUnauthorizedHandler(() => {
          setSupendedMsg("Your account is inactive, please contact support.");
          setSupendedTitle("Inactive");
          setShowSuspendedModal(true);
        });
    
        return () => {
          setUnauthorizedHandler(() => {});
        };
      }, []);

    const getUnAvailSlot = async () => {

        setLoading(true);
        setBackdropVisible(true);

        apiConfig.get(`/newnoslot?id=${localStorage.getItem('userId')}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status === false) {
                    setShowErrorToast(true);
                    setErrorMessage(response.data.message);
                } else {
                    const formattedDates = response.data.data.map((slot: any) => slot.slotdate);
                    setSelectedDatesList(response.data.data);
                    const slots = response.data.data.map((slot: any) => new Date(slot.slotdate));
                    setSelectedDates(slots);
                    setClickedDates(formattedDates);
                }
            })
            .catch(error => {
                setNetworkStatus(error.code !== "ERR_NETWORK");
            })
            .finally(() => {
                setLoading(false);
                setBackdropVisible(false);
            });
    };

    const handleDateClick = (date: Date) => {
        const formattedDate = formatDate(date);
        setClickedDates(prevDates => {
            const newDates = prevDates.includes(formattedDate)
                ? prevDates.filter(d => d !== formattedDate)
                : [...prevDates, formattedDate];
            return newDates;
        });
    };

    const formatDate = (date: Date) => { 
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const isTileDisabled = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const now = new Date();
            return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
        }
        return false;
    };

    const getClassNamesForDate = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const formattedDate = formatDate(date);
            const isSelected = clickedDates.includes(formattedDate);
            if (isSelected) return 'highlight';
            return '';
        }
        return '';
    };

    const handleActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date | null }) => {
        if (activeStartDate) {
            setCurrentMonth(activeStartDate);
        }
    };

    const deleteUnavailDate = (id: any) => {
        setLoading(true);
        setBackdropVisible(true);

        apiConfig.delete(`/noslot/${id}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status == false) {
                    setShowErrorToast(true);
                    setErrorMessage(response.data.message);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    setShowToastSuccess(true);
                    setSuccessMessage(response.data.message);
                    getUnAvailSlot();
                }

            })
            .catch(error => {
                setLoading(false);
                setBackdropVisible(false);
                setNetworkStatus(error.code !== "ERR_NETWORK");
            })
            .finally(() => {
                setLoading(false);
                setBackdropVisible(false);
            });
    }


    const updateUnAvailSlot = () => {
        if (clickedDates.length !== 0) {
            setLoading(true);
            setBackdropVisible(true);

            apiConfig.post(`/noslot`, {
                "userid": localStorage.getItem('userId'),
                "slotdate": clickedDates
            }, {
                headers: {
                    'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.status == false) {
                        setShowErrorToast(true);
                        setErrorMessage(response.data.message);
                        setLoading(false);
                        setBackdropVisible(false);
                    } else {
                        setShowToastSuccess(true);
                        setSuccessMessage(response.data.message);
                        getUnAvailSlot();
                        setLoading(false);
                        setBackdropVisible(false);
                    }

                    setTimeout(() => {
                        if (loading == true) {
                            setLoading(false);
                            setBackdropVisible(false);
                            setErrorMessage("Something went wrong on API please try again");
                            setShowErrorToast(true);
                        }
                    }, 10000);
                })
                .catch(error => {
                    if (error.code == "ERR_NETWORK") {
                        setNetworkStatus(false);
                    } else {
                        setNetworkStatus(true);
                    }
                    setLoading(false);
                    setBackdropVisible(false);
                });
        } else {
            setErrorMessage("Please select atleast one date");
            setShowErrorToast(true);
        }
    }


    // Availability


    const hourColumn = {
        name: 'hour',
        options: Array.from({ length: 24 }, (_, i) => ({ text: `${i}`.padStart(2, '0'), value: `${i}` })),
    };

    const minuteColumn = {
        name: 'minute',
        options: Array.from({ length: 60 }, (_, i) => ({ text: `${i}`.padStart(2, '0'), value: `${i}` })),
    };


    const convertTimeToMinutes = (time: string) => {
        const [timeStr, period] = time.split(' ');
        let [hours, minutes] = timeStr.split(':').map(Number);
    
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (period === 'AM' && hours === 12) {
            hours = 0; // midnight case
        }
    
        return hours * 60 + minutes;
    };

    const handleTimeChange = (e: any, type: string, dayIndex: number) => {
        const value = e.detail.value.trim();  // Get the updated time (from_time or to_time)
    
        setAvailability((prev: any) => {
            const updated = [...prev];
            updated[dayIndex][type] = value;  // Update either from_time or to_time
    
            // Create a dynamic payload based on what was changed
            let payload: any = { id: updated[dayIndex].id, api_version :"v1", };
            if (type === 'from_time') {
                payload.from_time = updated[dayIndex].from_time;
            } else if (type === 'to_time') {
                payload.to_time = updated[dayIndex].to_time;
            }
    
            // Send the appropriate payload (either from_time or to_time only)
            handleFromToDate(payload);
    
            return updated;
        });
    };

    const handleFromToDate = (payload: { id: number, from_time?: string, to_time?: string }) => {

        setLoading(true);
        setBackdropVisible(true);
    
        apiConfig.patch(`/slot/${localStorage.getItem('userId')}`, payload, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            // setLoading(false);
            // setBackdropVisible(false);
            setShowToastSuccess(true);
            setSuccessMessage(res.data.message);
            availGetFunction();
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                setNetworkStatus(false);
            } else {
                setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
        });
    };
    
    const timeOptions = [
        "24 Hours", "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", 
        "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", 
        "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", 
        "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", 
        "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", 
        "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
    ];
    
        const handleToggleLeaveChange = (event: any, dayIndex: number, avail: any) => {
            const isChecked = event.detail.checked ? "1" : "2";

            // Update the dropdownShow state with the new status
            setDropdownShow(prevState => ({
                ...prevState,
                [dayIndex]: isChecked
            }));

            // You may also want to update the `avail.status` directly here if necessary
            avail.status = isChecked;

        setLoading(true);
        setBackdropVisible(true);

        apiConfig.patch(`/slot/${localStorage.getItem('userId')}`, {
            "id": avail.id,
            "status": isChecked,
        }, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setShowToastSuccess(true);
                setSuccessMessage(res.data.message);
                availGetFunction();
                setLoading(false);
                setBackdropVisible(false);

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            })
            .catch((error) => {
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            })

        };


    useIonViewWillEnter(() => {
        availGetFunction();
    }, []);

    useEffect(() => {
        Network.addListener('networkStatusChange', status => {
            setNetworkStatus(status.connected);

        });
    }, []);

    const availGetFunction = () => {
        setLoading(true);
        setBackdropVisible(true);
        apiConfig.get(`/slot?id=${localStorage.getItem('userId')}&api_version=v1`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const availabilityData = response.data.data;

                // Set availability
                setAvailability(availabilityData);
        
                const dropdownState: { [key: number]: string } = {};
                availabilityData.forEach((avail: any, index: number) => {
                    dropdownState[index] = avail.status == "1" ? "1" : "2";
                });
        
                // Set the dropdown show state
                setDropdownShow(dropdownState);
        
                setLoading(false);
                setBackdropVisible(false);
        

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        setErrorMessage("Something went wrong on API please try again");
                        setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            });

    }

    const goBack = () => {
        history.goBack();
    };
    return (
        <IonPage>

            {networkStatus == false && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ textAlign: "center" }}>
                                Edit Profile
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                            <h6 className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
        {networkStatus === true && darkModeValue === "lightMode" &&
            <>
                <IonHeader className="ion-no-border">
                    <IonToolbar className="light-mode-header-toolbar">
                        <IonButtons slot="start">
                            <IonButton className="end-btn" onClick={goBack}>
                                <IonIcon
                                    className="fav header-icons"
                                    src="assets/imgs/icons/backArrow.svg"
                                    slot="end" />
                            </IonButton>
                        </IonButtons>
                        <IonTitle style={{ textAlign: "center" }}>Availability</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                className="end-btn"
                                routerLink="/notifications"
                                routerDirection="none">
                                <IonIcon
                                    className="fav header-icons"
                                    src="assets/imgs/icons/bell.svg"
                                    slot="end" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen className="profile">
                    <IonGrid>
                        <IonRow>
                          <IonCol size-md="6" size="12" >
                                <div className="general animate__animated animate__fadeInUp">
                                        <IonLabel style={{ fontSize: '20px', marginLeft: "35px",lineHeight: 2 }} className="main-title">My Working Hours</IonLabel>
                                    <IonGrid>
                                        {availability && availability.map((avail: any, dayIndex: number) => {
                                            return (
                                                <IonCol key={dayIndex} size-md="4" size-lg="3" size="12">
                                                    <IonGrid class="date-grid">
                                                        <IonRow className="row-avail">
                                                            <IonCol size-md="3" size="6">
                                                                <IonLabel className="day-title font-name-bold">{avail.slotday}</IonLabel>
                                                            </IonCol>
                                                            <IonCol size-md="3" size="6">
                                                                <div className="div-align ion-float-right">
                                                                    <IonToggle
                                                                        className="toggleActive"
                                                                        slot="end"
                                                                        checked={avail.status == "1"}
                                                                        onIonChange={(e) => handleToggleLeaveChange(e, dayIndex, avail)}
                                                                    />
                                                                </div>
                                                            </IonCol>

                                                            {dropdownShow[dayIndex] == "1" && (
                                                                <React.Fragment key={`${dayIndex}-dropdown`}>
                                                                    {avail.fullday == 1 && (
                                                                        <React.Fragment>
                                                                            <IonCol size-md="3" size="6">
                                                                                <div className="model-container">
                                                                                    <IonSelect
                                                                                        interface="popover"
                                                                                        className="date-ion-select"
                                                                                        value={avail.from_time?.trim()}
                                                                                        placeholder="Timing"
                                                                                        onIonChange={(e) => handleTimeChange(e, 'from_time', dayIndex)}
                                                                                    >
                                                                                        {timeOptions.map((time: string) => (
                                                                                            <IonSelectOption key={time} value={time}>
                                                                                                {time}
                                                                                            </IonSelectOption>
                                                                                        ))}
                                                                                    </IonSelect>
                                                                                    <label className="label-outline-model-avail">Timing</label>
                                                                                </div>
                                                                            </IonCol>
                                                                        </React.Fragment>
                                                                    )}

                                                                {avail.fullday == 2 && (
                                                                    <React.Fragment>
                                                                        <IonCol size-md="3" size="6">
                                                                            <div className="model-container">
                                                                                <IonSelect
                                                                                    interface="popover"
                                                                                    className="date-ion-select"
                                                                                    value={avail.to_time?.trim()}
                                                                                    placeholder="Timing"
                                                                                    onIonChange={(e) => handleTimeChange(e, 'to_time', dayIndex)}
                                                                                >
                                                                                    {timeOptions.map((time: string) => (
                                                                                        <IonSelectOption key={time} value={time}>
                                                                                            {time}
                                                                                        </IonSelectOption>
                                                                                    ))}
                                                                                </IonSelect>
                                                                                <label className="label-outline-model-avail">Timing</label>
                                                                            </div>
                                                                        </IonCol>
                                                                    </React.Fragment>
                                                                )}

                                                                {avail.fullday == 0 && (
                                                                    <React.Fragment>
                                                                        <IonCol size-md="3" size="6">
                                                                            <div className="model-container">
                                                                                <IonSelect
                                                                                    interface="popover"
                                                                                    className="date-ion-select"
                                                                                    value={avail.from_time?.trim()}  // Use the direct value without normalization
                                                                                    placeholder="Start Time"
                                                                                    onIonChange={(e) => handleTimeChange(e, 'from_time', dayIndex)}
                                                                                >
                                                                                    {timeOptions.map((time: string) => (
                                                                                        <IonSelectOption key={time} value={time}>
                                                                                            {time}
                                                                                        </IonSelectOption>
                                                                                    ))}
                                                                                </IonSelect>
                                                                                <label className="label-outline-model-avail">Start</label>
                                                                            </div>
                                                                        </IonCol>

                                                                        <IonCol size-md="3" size="6">
                                                                            <div className="model-container">
                                                                                <IonSelect
                                                                                    interface="popover"
                                                                                    className="date-ion-select"
                                                                                    value={avail.to_time?.trim()}
                                                                                    placeholder="Select End Time"
                                                                                    onIonChange={(e) => handleTimeChange(e, 'to_time', dayIndex)}
                                                                                >
                                                                                {timeOptions.map((time: string) => {
                                                                                    const isDisabled = convertTimeToMinutes(time) <= convertTimeToMinutes(avail.from_time);  // Disable if earlier than from_time
                                                                                    return (
                                                                                        <IonSelectOption key={time} value={time} disabled={isDisabled}>
                                                                                            {time}
                                                                                        </IonSelectOption>
                                                                                    );
                                                                                })}
                                                                                </IonSelect>
                                                                                <label className="label-outline-model-avail">End</label>
                                                                            </div>
                                                                        </IonCol>
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </IonRow>
                                                </IonGrid>
                                            </IonCol>
                                        );
                                    })}
                                    </IonGrid>
                                    <IonGrid className="d-d-none last-btn-grid">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel className="last-btn-grid-title">Set Unavailable Dates</IonLabel>
                                                <IonLabel className="fontName last-btn-grid-sec-title">You have not specified any dates as unavailable.</IonLabel>
                                                <IonButton expand="block" routerLink="/unavailable-dates"
                                                    routerDirection="none" className="unavail-btn">
                                                    Unavailable Dates
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </div>
                          </IonCol>
                            <IonCol size="6" className="m-d-none">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel style={{ fontSize: '20px',lineHeight: "2px"}} className="main-title">Select a date</IonLabel>
                                            <div className="animate__animated animate__bounceIn ion-text-center">
                                                <Calendar
                                                    tileClassName={getClassNamesForDate}
                                                    onClickDay={handleDateClick}
                                                    tileDisabled={isTileDisabled}
                                                    value={currentMonth}
                                                    onActiveStartDateChange={handleActiveStartDateChange}
                                                />

                                                <IonButton expand="block" onClick={updateUnAvailSlot} className="update-btn send-fp">
                                                    <IonIcon
                                                        className="fav btn-icon"
                                                        src="assets/imgs/icons/editPlus.svg"
                                                        slot="end"
                                                    /><span className="order-span">Update Unavailable Slots</span>
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        </IonRow>
                                        </IonGrid>
                                        <hr className="d-d-none" />
                                  <IonGrid>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel style={{ fontSize:"20px",lineHeight: "50px" }} className="main-title">Unavailable for these dates</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                     {selectedDatesList.length !== 0 &&
                                            <IonCard style={{ marginTop: "1%", padding: "10px" }} className="list-card" >
                                                <IonGrid>
                                                 <IonRow>
                                            { selectedDatesList.map((date: any, i: any) =>
                                           
                                                  <IonCol key={i} size="3">
                                                    <IonItem className="new-list-label" lines="none">
                                                        <label slot="start">{date.slotdate}</label> 
                                                        <IonIcon
                                                            className="X"
                                                            onClick={() => deleteUnavailDate(date.id)}
                                                            src="assets/imgs/icons/close-icon.svg"
                                                            slot="end"
                                                        />
                                                    </IonItem>
                                            </IonCol>
                                             )}
                                              
                                                </IonRow>
                                            </IonGrid>
                                        </IonCard>
                                            }
                                        {selectedDatesList.length == 0 &&
                                                        <IonCard className="nodate-card list-card">
                                                            <IonLabel className="nodate-label">No Data Available</IonLabel>
                                                        </IonCard>
                                                    }
                                </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonToast
                        isOpen={showToasSuccess}
                        onDidDismiss={() => setShowToastSuccess(false)}
                        message={successMessage}
                        duration={3000}
                        color="success" />
                    <IonLoading isOpen={loading} spinner="circular" translucent={true} />
                    <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
                        <IonContent className="model-bg">
                            <IonGrid className='cnfmModelGrid'>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonImg src="assets/imgs/icons/warning.svg" alt="Warning" className="tick-img"></IonImg>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                    </IonCol>
                                    <IonCol size='12'>
                                        <IonButton
                                            routerLink="/login"
                                            routerDirection="none"
                                            className='fontName booking-details-btn'
                                            onClick={() => {setShowSuspendedModal(false);localStorage.setItem("isLoggedIn", "no");}}
                                            expand="block">
                                            Back to Login
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                    </IonModal>
                    <IonLoading isOpen={loading} spinner="circular" translucent={true} />
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={errorMessage}
                        duration={3000}
                        color="danger" />
                </IonContent>
            </>
        }
            <WatiChat />
        </IonPage>
    );
};

export default NewAvailability;

