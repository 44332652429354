import { FingerprintAIO } from '@ionic-native/fingerprint-aio';

class FingerprintService {
    fingerprint: typeof FingerprintAIO;

    constructor() {
        this.fingerprint = FingerprintAIO;
    }

    async authenticate(): Promise<any> {
        try {
            const availabilityStatus = await this.fingerprint.isAvailable();

            if (["face", "finger", "biometric"].includes(availabilityStatus)) {
                const result_1 = await this.fingerprint.show({ description: "Glamdeva Pro" });

                // const userConfirmation = await this.confirmBiometricUsage();
                // if (userConfirmation) {
                //     return result_1;
                // } else {
                    // return this.retryAuthentication();
                // }
            } else {
                throw new Error("Biometric authentication not available.");
            }
        } catch (error) {
            throw error;
        }
    }

    // confirmBiometricUsage(): Promise<boolean> {
    //     return new Promise((resolve) => {
    //         const userConfirmed = window.confirm("Do you want to use Face ID/Fingerprint for authentication?");
    //         resolve(userConfirmed);
    //     });
    // }

    // async retryAuthentication(): Promise<any> {
    //     try {
    //         const availabilityStatus = await this.fingerprint.isAvailable();
    //         if (["face", "finger", "biometric"].includes(availabilityStatus)) {
    //             const result = await this.fingerprint.show({ description: "Please use Face ID/Fingerprint for authentication" });
    //             return result;
    //         } else {
    //             throw new Error("Biometric authentication not available.");
    //         }
    //     } catch (error) {
    //         throw error;
    //     }
    // }
}

const fingerprintService = new FingerprintService();
export default fingerprintService;